import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { templateConstants } from "_constants";

import { useSelector, useDispatch } from "react-redux";
import { TextField, SubmitButton } from "components/general";
import { templateAction } from "actions";

import { toast } from "react-toastify";

export const TemplateExportDialog = (props) => {
	const { handleOnClose, active, template } = props;
	const { submitTemplateSuccess } = useSelector(({ template }) => ({
		submitTemplateSuccess: template.submitTemplateSuccess,
	}));

	const dispatch = useDispatch();

	const useStyles = makeStyles({
		scrollPaper: {
			alignItems: "baseline", // default center
		},
	});
	const classes = useStyles();

	const initForm = {
		type: "adminmsg",
		name: "", //its template type, e.g. adminmsg, etc, not msg type
		category: "",
		title: "",
		detail: "",
		// addToCalendar: false,
	};

	const [formData, setFormData] = useState(initForm);

	const handleChange = ({ key, value }) => {
		setFormData({ ...formData, [key]: value });
	};

	useEffect(() => {
		setFormData({
			...formData,
			title: template.title,
			category: template.category,
			detail: template.detail,
		});
	}, [template]);

	useEffect(() => {
		if (submitTemplateSuccess) {
			dispatch({ type: templateConstants.type.RESET_TEMPLATE_SUCCESS });
			toast.success("成功導出模板");
			handleOnClose();
		}
	}, [submitTemplateSuccess, handleOnClose, dispatch]);

	const handleSubmit = () => {
		if (!formData.name) {
			toast.error("請填寫模板名稱");
			return;
		}

		const data = {
			type: formData.type,
			name: formData.name,
			category: formData.category,
			title: formData.title,
			detail: formData.detail,
		};

		dispatch(templateAction.createTemplate(data));
	};

	return (
		<Dialog
			classes={{ scrollPaper: classes.scrollPaper }}
			fullWidth={true}
			maxWidth="sm"
			open={active}
			onClose={handleOnClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="customized-dialog-title" onClose={handleOnClose}>
				{" "}
				- 導出訊息模板
				<IconButton style={{ position: "absolute", top: 0, right: 0 }} onClick={handleOnClose}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Block>
					<Subtitle>模板名稱</Subtitle>
					<RowInBlock>
						<TextField placeholder="請輸入名稱" value={formData.name} name="name" handleChange={handleChange} />
					</RowInBlock>
				</Block>
			</DialogContent>
			<DialogActions>
				<SubmitButton label={"導出"} handleOnClick={handleSubmit} />
			</DialogActions>
		</Dialog>
	);
};

const Block = styled.div`
	margin-top: ${(props) => props.marginTop || "10px"};
	flex-direction: column;
`;

const RowInBlock = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.marginBottom || "10px"};
	flex-direction: row;
`;

const Subtitle = styled.div`
	font-size: 1.1em;
	color: black;
	margin-bottom: ${(props) => props.marginBottom || "10px"};
`;

TemplateExportDialog.propTypes = {
	handleOnClose: PropTypes.func,
	active: PropTypes.bool,
	template: PropTypes.any,
};

TemplateExportDialog.defaultProps = {
	active: true,
	template: {},
};
