import React, { useState, useEffect } from "react";
import { Prompt } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Loading, Select, TextField, SubmitButton, CancelButton, Checkbox, SingleImageUploader } from "components/general";
import _ from "lodash";
import { generalConstants, schoolConstants, schoolDataConstants, DESKTOP_MIN_WIDTH } from "_constants";

import { schoolAction } from "actions";
import { useLocation } from "react-router-dom";
import { history } from "_util/_helper";
import { toast } from "react-toastify";
import { Desktop } from "components/responsive";
import { AdminMenubar, AdminUserInfo, AdminSectionbar, AdminFooter } from "components/admin";
import { PageContainer, LeftContainer, RightContainer, ContentContainer } from "components/layout";

export const EditSchoolPage = () => {
	const location = useLocation();
	const [key, setSchoolKey] = useState(location?.state?.schoolKey);

	useEffect(() => {
		setSchoolKey(location?.state?.schoolKey);
	}, [location?.state]);

	const subsidiaryList = useSelector(({ auth }) => auth.subsidiaryList);

	const { isFetching, updateSchoolSuccess, school, loggedIn } = useSelector(({ school, auth }) => {
		const res = {
			isFetching: school.isFetching,
			updateSchoolSuccess: school.updateSchoolSuccess,
			loggedIn: auth.loggedIn,
		};
		if (key) res.school = school.school;
		return res;
	});

	const baseURL = `${process.env.REACT_APP_SCHOOL_BADGE_LINK}`;
	const schoolIdLimit = parseInt(process.env.REACT_APP_SCHOOL_ID_LENGTH);
	const [isLoading, setIsLoading] = useState(false);
	const initForm = {
		key: "",
		level: schoolConstants.level.PRIMARY.key,
		name: ["", ""],
		address: ["", ""],
		telNo: "",
		faxNo: "",
		website: "",
		gender: schoolConstants.OPTION.gender[0].key,
		religion: "",
		financeType: schoolConstants.OPTION.financeType.PRIMARY[0].key,
		session: [schoolConstants.OPTION.session[0].key],
		district: schoolConstants.OPTION.district[0].key,
		linkMode: "",
		schoolNet: "",
		kes: null,
		category: schoolConstants.OPTION.category.PRIMARY[0].key,
		status: schoolConstants.status.ACTIVE.key,
		clearImage: false,
		schoolBadge: null,
	};

	const [formData, setFormData] = useState(initForm);
	const [isChanged, setIsChanged] = useState(false);
	const [isSaved, setIsSaved] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		if (key && loggedIn) dispatch(schoolAction.getSchoolByKey(key, {}));
	}, [key, loggedIn, dispatch]);

	useEffect(() => {
		setIsLoading(isFetching);
	}, [isFetching]);

	const handleChange = ({ key, value }) => {
		let tempData = { ...formData };
		if (key === "level") {
			if (value !== tempData.level) {
				if (value === "SECONDARY") {
					tempData.category = schoolConstants.OPTION.category.SECONDARY[0].key;
					tempData.financeType = schoolConstants.OPTION.financeType.SECONDARY[0].key;
				} else if (value === "PRIMARY") {
					_.pull(tempData.session, "EVENING");
					tempData.category = schoolConstants.OPTION.category.PRIMARY[0].key;
					tempData.financeType = schoolConstants.OPTION.financeType.PRIMARY[0].key;
				} else if (value === "KINDERGARTEN") {
					_.pull(tempData.session, "EVENING");
					tempData.category = schoolConstants.OPTION.category.KINDERGARTEN[0].key;
					tempData.financeType = schoolConstants.OPTION.financeType.KINDERGARTEN[0].key;
				} else if (value === "KINDERGARTEN-CUM-CHILD CARE CENTRES") {
					_.pull(tempData.session, "EVENING");
					tempData.category = schoolConstants.OPTION.category["KINDERGARTEN-CUM-CHILD CARE CENTRES"][0].key;
					tempData.financeType = schoolConstants.OPTION.financeType["KINDERGARTEN-CUM-CHILD CARE CENTRES"][0].key;
				}
			}
			tempData[key] = value;
		} else if (key === "name0") {
			tempData.name[0] = value;
		} else if (key === "name1") {
			tempData.name[1] = value;
		} else if (key === "address0") {
			tempData.address[0] = value;
		} else if (key === "address1") {
			tempData.address[1] = value;
		} else if (key === "kes") {
			tempData.kes = tempData.kes === "Y" ? "" : "Y";
		} else if (key === "session") {
			if (_.includes(tempData.session, value)) {
				_.pull(tempData.session, value);
			} else {
				tempData.session.push(value);
			}
		} else {
			tempData[key] = value;
		}

		setFormData(tempData);

		setIsChanged(true);
	};

	const getCategoryOption = (inputLevel) => {
		switch (inputLevel) {
			case "SECONDARY":
				return schoolConstants.OPTION.category.SECONDARY;
			case "PRIMARY":
				return schoolConstants.OPTION.category.PRIMARY;
			case "KINDERGARTEN":
				return schoolConstants.OPTION.category.KINDERGARTEN;
			case "KINDERGARTEN-CUM-CHILD CARE CENTRES":
				return schoolConstants.OPTION.category["KINDERGARTEN-CUM-CHILD CARE CENTRES"];
			default:
				return "";
		}
	};

	const validateInputs = () => {
		if (!formData.name[0] && !formData.name[1]) {
			toast.error("請填寫學校名稱");
			return false;
		}

		if (!formData.address[0] && !formData.address[1]) {
			toast.error("請填寫地址");
			return false;
		}

		if (formData.status && !_.has(schoolConstants.status, formData.status)) {
			toast.error("狀態輸入有誤");
			return false;
		}

		if (isBureau(key)) return true;

		if (!_.has(schoolConstants.gender, formData.gender)) {
			toast.error("學生性別輸入有誤");
			return false;
		}
		if (formData.religion && !_.has(schoolConstants.religion, formData.religion)) {
			toast.error("宗教輸入有誤");
			return false;
		}
		if (!_.has(schoolConstants.level, formData.level)) {
			toast.error("學校類型輸入有誤");
			return false;
		}
		if (
			formData.level === schoolConstants.level.PRIMARY.key &&
			!_.isEmpty(formData.schoolNet) &&
			!_.has(schoolConstants.schoolNet, formData.schoolNet)
		) {
			toast.error("校網輸入有誤");
			return false;
		}

		if (formData.session && !_.isArray(formData.session)) {
			toast.error("上課時間輸入有誤");
		}

		_.forEach(formData.session, function (s) {
			if (formData.level === schoolConstants.level.SECONDARY.key && s === "EVENING") {
				return;
			} else if (!_.has(schoolConstants.session, s)) {
				toast.error("上課時間輸入有誤");
			}
		});

		if (!_.has(schoolConstants.district, formData.district)) {
			toast.error("區域輸入有誤");
			return false;
		}

		if (!schoolConstants.OPTION.financeType[formData.level].map((a) => a.key).includes(formData.financeType)) {
			toast.error("財政類型輸入有誤");
			return false;
		}
		if (
			formData.level === schoolConstants.level.PRIMARY.key &&
			!_.isEmpty(formData.linkMode) &&
			!_.has(schoolConstants.linkMode, formData.linkMode)
		) {
			toast.error("連結模式輸入有誤");
			return false;
		}
		if (
			formData.level !== schoolConstants.level.PRIMARY.key &&
			formData.level !== schoolConstants.level.SECONDARY.key &&
			formData.kes &&
			!_.has(schoolConstants.kes, formData.kes)
		) {
			toast.error("幼稚園教育計劃輸入有誤");
			return false;
		}

		return true;
	};

	const validateIDInput = (schoolId) => {
		if (!/\d+/.test(schoolId) || _.size(schoolId) !== schoolIdLimit) {
			toast.error(`請填寫${schoolIdLimit}位數字的學校ID`);
			return false;
		}
		return true;
	};

	const handleChangeImage = (file) => {
		setFormData({ ...formData, clearImage: false, schoolBadge: file });
		setIsChanged(true);
	};

	const handleClearImage = () => {
		setFormData({ ...formData, clearImage: true, schoolBadge: null });
		setIsChanged(true);
	};

	const handleSubmit = () => {
		if (!validateInputs()) return;

		if (!key && !validateIDInput(formData.key)) return;

		const data = {
			key: key || formData.key,
			name: formData.name,
			status: formData.status,
		};
		if (!isBureau(key)) {
			_.merge(data, {
				address: formData.address,
				telNo: formData.telNo,
				faxNo: formData.faxNo,
				website: formData.website,
				gender: formData.gender,
				religion: formData.religion,
				level: formData.level,
				session: formData.session,
				district: formData.district,
				financeType: formData.financeType,
				category: formData.category,
				schoolBadge: formData.schoolBadge,
				organizationKey: formData.organizationKey,
			});

			if (formData.level === "PRIMARY") {
				_.merge(data, {
					schoolNet: formData.schoolNet,
					linkMode: formData.linkMode,
				});
			}
			if (formData.level === "KINDERGARTEN-CUM-CHILD CARE CENTRES" || formData.level === "KINDERGARTEN") {
				_.merge(data, {
					kes: formData.kes,
				});
			}

			if (formData.clearImage) {
				_.merge(data, {
					clearImage: formData.clearImage,
				});
			}
		}
		if (key) {
			_.merge(data, {
				rev: school._rev,
			});
			dispatch(schoolAction.updateSchool(data));
		}
	};

	useEffect(() => {
		if (!school) return;
		const tempForm = {};
		Object.keys(formData).forEach((key) => {
			switch (key) {
				case "key":
					tempForm[key] = school["_key"] !== undefined ? school["_key"] : formData[key];
					break;
				case "religion":
					tempForm[key] = _.has(schoolConstants.religion, school[key]) ? school[key] : formData[key];
					break;
				case "session":
					if (!_.isArray(school[key])) tempForm[key] = formData[key];
					else {
						tempForm[key] = [];
						_.forEach(school[key], function (s) {
							if (_.has(schoolConstants.session, s)) {
								tempForm[key].push(s);
							}
						});
					}
					break;
				case "schollBadge":
				case "clearImage":
				case "organizationKey":
				case "organizationName":
					break;
				default:
					tempForm[key] = school[key] !== undefined ? school[key] : formData[key];
					break;
			}
		});
		if (school.organizationKey && school.organizationKey) {
			tempForm["organizationKey"] = school.organizationKey;
		}
		setFormData(tempForm);
	}, [school]);

	useEffect(() => {
		if (updateSchoolSuccess) {
			if (!isSaved) {
				setIsSaved(true);
				return;
			}
			dispatch({
				type: schoolConstants.type.RESET_SCHOOL_SUCCESS,
				filePath: !_.isNull(formData.schoolBadge) ? URL.createObjectURL(formData.schoolBadge) : "",
			});
			toast.success("已更新學校");
			history.goBack();
		}
	}, [updateSchoolSuccess, isSaved, dispatch]);

	const handleBack = () => {
		if (key) {
			history.push({
				pathname: generalConstants.PATH.SCHOOL_DETAIL,
				state: { schoolKey: key },
			});
		} else {
			history.push(generalConstants.PATH.SCHOOL_MANAGEMENT);
		}
	};

	const isBureau = (key) => {
		return key === schoolDataConstants.specialSchoolId;
	};

	const getLocationPath = () => {
		const pathArray = [];

		if (subsidiaryList?.schoolList?.totalItems !== 1 && subsidiaryList?.schoolList?.items?.length !== 1) {
			pathArray.push({
				title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.SCHOOL_MANAGEMENT.key],
				isAction: true,
				target: generalConstants.PATH.SCHOOL_MANAGEMENT,
			});
		}
		if (key && school && typeof school.name != "undefined") {
			pathArray.push({
				title: school?.name[0] || school?.name[1] || "",
				isAction: true,
				target: generalConstants.PATH.SCHOOL_DETAIL,
				stateObj: { schoolKey: key },
			});
		}
		pathArray.push({
			title:
				generalConstants.TAB_NAME[
					key && school && typeof school.name != "undefined"
						? generalConstants.NAV_TAB.EDIT_SCHOOL.key
						: generalConstants.NAV_TAB.CREATE_SCHOOL.key
				],
			isAction: false,
			target: "",
		});
		return pathArray;
	};

	return (
		<React.Fragment>
			{!!isLoading && <Loading />}
			<Prompt when={isChanged && !isSaved && loggedIn} message="你有未儲存的變更，確定不儲存下離開？" />
			<PageContainer>
				<LeftContainer>
					<AdminMenubar section={generalConstants.NAV_TAB.SCHOOL_MANAGEMENT.key} />
				</LeftContainer>
				<RightContainer>
					<Desktop>
						<AdminUserInfo />
					</Desktop>
					<AdminSectionbar
						title={key ? generalConstants.NAV_TAB.EDIT_SCHOOL.key : generalConstants.NAV_TAB.CREATE_SCHOOL.key}
						locationPathArray={getLocationPath()}
					/>
					<ContentContainer padding="16px 20px 17px" style={{ display: "flex", flexDirection: "row" }}>
						<ContentColumnsContainer>
							<ContentLeft>
								<Block>
									<TextFieldLabel>學校ID</TextFieldLabel>
									<TextField placeholder="學校ID" disabled={!!key} value={formData.key} name="key" handleChange={handleChange} />
								</Block>
								<BlockGroup>
									{!isBureau(key) && (
										<Block flex="0 0 135px">
											<TextFieldLabel>學校徽標</TextFieldLabel>
											<SingleImageUploader
												src={`${baseURL}${key}.jpg`}
												handleChange={handleChangeImage}
												handleClear={handleClearImage}
												uploadLabel="上傳校徽"
												noImageLabel="沒有校徽"
											/>
										</Block>
									)}
									<Block>
										<TextFieldLabel>名稱</TextFieldLabel>
										<TextField placeholder="名稱" value={formData.name[0]} name="name0" handleChange={handleChange} />
										<TextFieldLabel style={{ marginTop: "9px" }}>Name</TextFieldLabel>
										<TextField placeholder="Name" value={formData.name[1]} name="name1" handleChange={handleChange} />
									</Block>
								</BlockGroup>
								{!isBureau(key) && (
									<div>
										<BlockGroup>
											<Block>
												<TextFieldLabel>學校類型</TextFieldLabel>
												<React.Fragment>
													<Select
														name={"level"}
														options={schoolConstants.OPTION.level}
														value={formData.level}
														handleChange={handleChange}
													/>
												</React.Fragment>
											</Block>
											<Block>
												<TextFieldLabel>學校類別</TextFieldLabel>
												<React.Fragment>
													<Select
														name={"category"}
														options={getCategoryOption(formData.level)}
														value={formData.category}
														handleChange={handleChange}
													/>
												</React.Fragment>
											</Block>
										</BlockGroup>
										<BlockGroup>
											<Block>
												<TextFieldLabel>地區</TextFieldLabel>
												<React.Fragment>
													<Select
														name={"district"}
														options={schoolConstants.OPTION.district}
														value={formData.district}
														handleChange={handleChange}
													/>
												</React.Fragment>
											</Block>
											{formData.level === "PRIMARY" && (
												<Block>
													<TextFieldLabel>校網</TextFieldLabel>
													<React.Fragment>
														<Select
															displayEmpty
															name={"schoolNet"}
															options={schoolConstants.OPTION.schoolNet}
															value={formData.schoolNet}
															handleChange={handleChange}
														/>
													</React.Fragment>
												</Block>
											)}

											<Block>
												<TextFieldLabel>學生性別</TextFieldLabel>
												<React.Fragment>
													<Select
														name={"gender"}
														options={schoolConstants.OPTION.gender}
														value={formData.gender}
														handleChange={handleChange}
													/>
												</React.Fragment>
											</Block>
										</BlockGroup>

										<BlockGroup>
											<Block>
												<TextFieldLabel>財政類型</TextFieldLabel>
												<React.Fragment>
													<Select
														name={"financeType"}
														options={schoolConstants.OPTION.financeType[formData.level]}
														value={formData.financeType}
														handleChange={handleChange}
													/>
												</React.Fragment>
											</Block>

											{formData.level === "PRIMARY" && (
												<Block>
													<TextFieldLabel>連結模式</TextFieldLabel>
													<React.Fragment>
														<Select
															displayEmpty
															name={"linkMode"}
															options={schoolConstants.OPTION.linkMode}
															value={formData.linkMode}
															handleChange={handleChange}
														/>
													</React.Fragment>
												</Block>
											)}

											<Block>
												<TextFieldLabel>宗教</TextFieldLabel>
												<React.Fragment>
													<Select
														displayEmpty
														name={"religion"}
														options={schoolConstants.OPTION.religion}
														value={formData.religion}
														handleChange={handleChange}
													/>
												</React.Fragment>
											</Block>
										</BlockGroup>

										<BlockGroup>
											<Block>
												<TextFieldLabel>授課時間</TextFieldLabel>
												<React.Fragment>
													{schoolConstants.OPTION.session
														.filter(
															(o) => !(formData.level !== "SECONDARY" && o.key === "EVENING") && _.has(schoolConstants.session, o.key)
														)
														.map((item) => {
															return (
																<Checkbox
																	key={`${item.key}_checkbox`}
																	name={"session"}
																	label={item.value}
																	checked={_.includes(formData.session, item.key)}
																	value={item.key}
																	handleChange={handleChange}
																/>
															);
														})}
												</React.Fragment>
											</Block>

											{formData.level !== "PRIMARY" && formData.level !== "SECONDARY" && (
												<Block>
													<TextFieldLabel>幼稚園教育計劃</TextFieldLabel>
													<Checkbox
														key={`kes_checkbox`}
														name={"kes"}
														label={`幼稚園教育計劃`}
														checked={formData.kes === "Y"}
														value={"Y"}
														handleChange={handleChange}
													/>
												</Block>
											)}
										</BlockGroup>
									</div>
								)}
							</ContentLeft>
							<ContentRight>
								{!isBureau(key) && (
									<div style={{ minHeight: "460px" }}>
										<Block>
											<TextFieldLabel>地址</TextFieldLabel>
											<TextField
												placeholder="地址"
												multiline
												rows={3}
												value={formData.address[0]}
												name="address0"
												handleChange={handleChange}
											/>
										</Block>
										<Block>
											<TextFieldLabel>Address</TextFieldLabel>
											<TextField
												placeholder="Address"
												multiline
												rows={3}
												value={formData.address[1]}
												name="address1"
												handleChange={handleChange}
											/>
										</Block>

										<BlockGroup>
											<Block>
												<TextFieldLabel>電話</TextFieldLabel>
												<TextField placeholder="電話" value={formData.telNo} name="telNo" handleChange={handleChange} />
											</Block>
											<Block>
												<TextFieldLabel>傳真</TextFieldLabel>
												<TextField placeholder="電話" value={formData.faxNo} name="faxNo" handleChange={handleChange} />
											</Block>
										</BlockGroup>
										<Block>
											<TextFieldLabel>網址</TextFieldLabel>
											<TextField placeholder="網址" value={formData.website} name="website" handleChange={handleChange} />
										</Block>

										<Block>
											<TextFieldLabel>狀態</TextFieldLabel>
											<React.Fragment>
												<Select
													name={"status"}
													options={schoolConstants.OPTION.status}
													value={formData.status}
													handleChange={handleChange}
												/>
											</React.Fragment>
										</Block>
									</div>
								)}
								<RowInBlock style={{ justifyContent: "flex-end" }}>
									<CancelButton label={"取消"} style={{ marginRight: "15px" }} handleOnClick={handleBack} />
									<SubmitButton label={"確認"} style={{ marginRight: "15px" }} handleOnClick={handleSubmit} active={isChanged} />
								</RowInBlock>
							</ContentRight>
						</ContentColumnsContainer>
					</ContentContainer>
					<AdminFooter></AdminFooter>
				</RightContainer>
			</PageContainer>
		</React.Fragment>
	);
};

const RowInBlock = styled.div`
	padding-top: 30px;
	display: flex;
	flex-direction: row;
`;

const ContentColumnsContainer = styled.div`
	@media (min-width: ${DESKTOP_MIN_WIDTH}) {
		flex-direction: row;
		display: flex;
	}
`;

const ContentLeft = styled.div`
	flex: 1;
	@media (min-width: ${DESKTOP_MIN_WIDTH}) {
		padding-right: 20px;
		border-right: 1px solid #dddddd;
	}
`;

const ContentRight = styled.div`
	flex: 1;
	@media (min-width: ${DESKTOP_MIN_WIDTH}) {
		padding: 10px;
	}
`;

const BlockGroup = styled.div`
	display: flex;
	flex-direction: row;
`;

const Block = styled.div`
	padding: 10px;
	flex: ${(props) => props.flex || 1};
`;

const TextFieldLabel = styled.div`
	color: #000000;
	font-size: 13px;
	line-height: 1.5;
	font-weight: bold;
	margin-bottom: 7px;
`;
