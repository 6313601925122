import React, { useState, useEffect } from "react";
import { Prompt } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import {
	Loading,
	OrganizationStatus,
	TextField,
	SubmitButton,
	CancelButton,
	SingleImageUploader,
	BlockMobileOverlay,
} from "components/general";
import ProgressiveRejectDebouncer from "_util/debouncers/ProgressiveRejectDebouncer";
import { generalConstants, authConstants, DESKTOP_MIN_WIDTH, MOBILE_MAX_WIDTH } from "_constants";
import { history } from "_util/_helper";
import { toast } from "react-toastify";
import { AdminMenubar, AdminUserInfo, AdminSectionbar, AdminFooter } from "components/admin";
import { PageContainer, LeftContainer, RightContainer, ContentContainer } from "components/layout";
import editIcon from "resources/img/school_inner_pencil.svg";
import IconButton from "@material-ui/core/IconButton";
import { MenuItem, Select as MUISelect } from "@material-ui/core";
import { authAction } from "actions";
import { authService } from "services";
import _ from "lodash";
import moment from "moment";
import { Desktop } from "components/responsive";

export const EditOrganizationPage = () => {
	const isFetching = useSelector(({ auth }) => auth.isFetching);
	const currentUser = useSelector(({ auth }) => auth.user);
	const updateOrganizationSuccess = useSelector(({ auth }) => auth.updateOrganizationSuccess);
	const loggedIn = useSelector(({ auth }) => auth.loggedIn);

	const [user, setUser] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const initForm = {
		contactPerson: "",
		email: "",
		name: "",
		address: "",
		whatsapp: "",
		facebook: "",
		website: "",
		isMultiCenter: false,
		numOfCenter: "1",
		phone: "",
		status: "ACTIVE",
		approvalStatus: "APPROVED",
		remark: "",
		profilePic: "",
		rev: "",
	};
	const [enableEditEmail, setEnableEditEmail] = useState(false);
	const [formData, setFormData] = useState(initForm);
	const [isChanged, setIsChanged] = useState(false);
	const dispatch = useDispatch();
	const [debouncer] = useState(() => new ProgressiveRejectDebouncer(2 * 1000));

	useEffect(() => {
		if (isChanged && !isFetching && updateOrganizationSuccess) {
			return;
		}
		if (currentUser && !_.isEmpty(currentUser) && user && !_.isEmpty(user)) {
			if (moment(currentUser.lastUpdateTime).isAfter(user.lastUpdateTime)) {
				getOrganization(true);
				return;
			}
		}
		const userData =
			typeof user != "undefined" && !_.isEmpty(user)
				? user
				: typeof currentUser != "undefined" && !_.isEmpty(currentUser)
				? currentUser
				: null;
		if (userData != null) {
			setFormData({
				...formData,
				contactPerson: userData.contactPerson,
				email: userData.email,
				phone: userData.phone,
				facebook: userData.facebook,
				whatsapp: userData.whatsapp,
				name: userData.name,
				address: userData.address,
				website: userData.website,
				status: userData.status,
				approvalStatus: userData.approvalStatus,
				remark: userData.remark,
				isMultiCenter: userData.isMultiCenter,
				numOfCenter: userData.numOfCenter,
				profilePic: userData.profilePic || "",
				rev: userData._rev || "",
			});
		}
	}, [user, currentUser]);

	useEffect(() => {
		if (loggedIn) {
			getOrganization(false);
		}
	}, [dispatch]);

	useEffect(() => {
		if (isChanged && !isFetching && updateOrganizationSuccess) {
			setIsChanged(false);
			history.goBack();
		}
		if (isFetching) {
			debouncer.token();
		}
	}, [isFetching, updateOrganizationSuccess]);

	const getOrganization = (forceUpdate) => {
		setIsLoading(true);
		authService.getOrganization(forceUpdate, false).then(
			(response) => {
				setIsLoading(false);
				if (response) {
					setUser(response);
					if (forceUpdate) {
						dispatch({
							type: authConstants.type.FETECH_ORGANIZATION_SUCCESS,
							data: response,
						});
					}
				}
			},
			(error) => {
				setIsLoading(false);
				toast.error(error.error);
			}
		);
	};

	const handleChange = ({ key, value }) => {
		setFormData({ ...formData, [key]: value });
		setIsChanged(true);
	};
	const HandleEditButtonOnClick = () => {
		setEnableEditEmail((prev) => !prev);
	};

	const handleChangeImage = (file) => {
		setFormData({ ...formData, profilePic: file });
		setIsChanged(true);
	};

	const handleClearImage = () => {
		setFormData({ ...formData, profilePic: "" });
		setIsChanged(true);
	};

	const handleSubmit = () => {
		if (!formData.name) {
			toast.error("請填寫機構/學校名稱");
			return;
		}
		if (
			!formData.email ||
			// eslint-disable-next-line no-useless-escape
			!/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/.test(
				formData.email
			)
		) {
			toast.error("請填寫有效的電郵地址");
			return;
		}
		if (!formData.phone) {
			toast.error("請填寫電話");
			return;
		}
		if (!formData.address) {
			toast.error("請填寫機構/學校地址");
			return;
		}
		if (!formData.contactPerson) {
			toast.error("請填寫聯絡人名字");
			return;
		}

		if (formData.isMultiCenter && (!/^[0-9]*$/.test(formData.numOfCenter) || formData.numOfCenter < 1)) {
			toast.error("必須輸入正分校數");
		}

		if (!debouncer.token()) {
			toast.error(`請稍後再試`);
			return;
		}

		dispatch(authAction.updateOrganization(formData));
	};

	const handleBack = () => {
		history.goBack();
	};

	const handleIsMultiCenterChange = (event) => {
		if (event.target.value === "false") {
			setFormData({ ...formData, isMultiCenter: false, numOfCenter: "1" });
		} else {
			setFormData({ ...formData, isMultiCenter: true });
		}
		setIsChanged(true);
	};

	const handleChangeNumOfCenter = ({ key, value }) => {
		if (!!value && (!/^[0-9]*$/.test(value) || value < 1)) {
			toast.error("必須輸入正整數");
		} else {
			setFormData({ ...formData, [key]: value });
		}
		setIsChanged(true);
	};

	return (
		<React.Fragment>
			{(!!isLoading || !!isFetching) && <Loading />}
			<Prompt when={loggedIn && isChanged} message="你有未儲存的變更，確定不儲存下離開？" />
			<PageContainer>
				<LeftContainer>
					<AdminMenubar section={generalConstants.NAV_TAB.ACCOUNT_MANAGEMENT.key} />
				</LeftContainer>
				<RightContainer>
					<Desktop>
						<AdminUserInfo />
					</Desktop>
					<AdminSectionbar title={generalConstants.NAV_TAB.ACCOUNT_MANAGEMENT.key} />
					<ContentContainer padding="16px 20px 17px">
						<TitleFieldLabel style={{ marginLeft: 5 }}>- 帳戶資料</TitleFieldLabel>
						<ContentColumnsContainer>
							<ContentLeft>
								<BlockGroup>
									<TopBlock flex="0 0 135px">
										<TextFieldLabel>頭像</TextFieldLabel>
										<SingleImageUploader
											src={formData.profilePic ?? ""}
											handleChange={handleChangeImage}
											handleClear={handleClearImage}
											uploadLabel="上傳圖片"
											noImageLabel="沒有圖片"
										/>
									</TopBlock>

									<AvatarBlock>
										<TextFieldLabel>狀態</TextFieldLabel>
										<OrganizationStatus style={{ fontSize: 13, lingHeight: 1.5, fontWeight: 500 }} item={formData} />
										<TextFieldLabel style={{ marginTop: "9px" }}>聯絡人名字*</TextFieldLabel>
										<TextField placeholder="聯絡人名字" value={formData.contactPerson} name="contactPerson" handleChange={handleChange} />
									</AvatarBlock>
								</BlockGroup>

								<div>
									<BlockGroup>
										<Block>
											<TextFieldLabel>電郵*</TextFieldLabel>
											<BlockGroup>
												<TextField
													placeholder="電郵"
													disabled={!enableEditEmail}
													value={formData.email}
													name="email"
													handleChange={handleChange}
												/>
												<IconButton aria-label="編輯" onClick={HandleEditButtonOnClick} style={{ padding: "5px" }}>
													<img alt="" src={editIcon} />
												</IconButton>
											</BlockGroup>
										</Block>
									</BlockGroup>

									<BlockGroup>
										<Block>
											<TextFieldLabel>電話*</TextFieldLabel>
											<TextField placeholder="電話" value={formData.phone} name="phone" handleChange={handleChange} />
										</Block>
									</BlockGroup>

									<BlockGroup>
										<Block>
											<TextFieldLabel>Whatsapp</TextFieldLabel>
											<TextField placeholder="Whatsapp" value={formData.whatsapp} name="whatsapp" handleChange={handleChange} />
										</Block>
									</BlockGroup>

									<BlockGroup>
										<Block>
											<TextFieldLabel>Facebook連結</TextFieldLabel>
											<TextField placeholder="Facebook連結" value={formData.facebook} name="facebook" handleChange={handleChange} />
										</Block>
									</BlockGroup>
								</div>
							</ContentLeft>
							<ContentRight style={{ paddingTop: 5 }}>
								<div style={{ minHeight: "460px" }}>
									<BlockGroup>
										<Block style={{ marginTop: 0, paddingTop: 0 }}>
											<TextFieldLabel>機構/學校名稱*</TextFieldLabel>
											<TextField placeholder="機構/學校名稱" value={formData.name} name="name" handleChange={handleChange} />
										</Block>
									</BlockGroup>
									<Block>
										<TextFieldLabel>機構/學校地址*</TextFieldLabel>
										<TextField
											placeholder="機構/學校地址"
											multiline
											rows={5}
											value={formData.address}
											name="address"
											handleChange={handleChange}
										/>
									</Block>
									<Block>
										<TextFieldLabel>網址</TextFieldLabel>
										<TextField placeholder="網址" value={formData.website} name="website" handleChange={handleChange} />
									</Block>
									<div style={{ paddingLeft: 10, paddingTop: 10, marginBottom: 0, paddingBottom: 0 }}>
										<TextFieldLabel>需要管理多間分校嗎?*</TextFieldLabel>
									</div>
									<Block style={{ paddingTop: 0, marginTop: 10 }}>
										<MUISelect
											value={formData.isMultiCenter ? "true" : "false"}
											onChange={handleIsMultiCenterChange}
											input={<TextField value={"isMultiCenter"} style={{ backgroundColor: "white" }} fullWidth={false} />}
										>
											<MenuItem key={"Yes"} value={"true"}>
												{"需要"}
											</MenuItem>
											<MenuItem key={"No"} value={"false"}>
												{"不需要"}
											</MenuItem>
										</MUISelect>
										<TextField
											style={{ marginLeft: 10, backgroundColor: "white", width: 100 }}
											fullWidth={false}
											value={formData.numOfCenter}
											name="numOfCenter"
											disabled={!formData.isMultiCenter}
											handleChange={handleChangeNumOfCenter}
										/>
									</Block>
								</div>
							</ContentRight>
						</ContentColumnsContainer>
						<RowInBlock style={{ justifyContent: "flex-end" }}>
							<CancelButton label={"取消"} style={{ marginRight: "15px" }} handleOnClick={handleBack} />
							<SubmitButton
								label={"確認"}
								style={{ marginRight: "15px" }}
								handleOnClick={handleSubmit}
								active={isChanged && !isFetching}
								disabled={isFetching}
							/>
						</RowInBlock>
					</ContentContainer>
					<AdminFooter></AdminFooter>
				</RightContainer>
			</PageContainer>
			<BlockMobileOverlay />
		</React.Fragment>
	);
};

const RowInBlock = styled.div`
	padding-top: 30px;
	display: flex;
	flex-direction: row;
`;

const ContentColumnsContainer = styled.div`
	@media (min-width: ${DESKTOP_MIN_WIDTH}) {
		flex-direction: row;
		display: flex;
	}
`;

const ContentLeft = styled.div`
	flex: 1;
	max-width: 410px;
	@media (min-width: ${DESKTOP_MIN_WIDTH}) {
		padding-right: 15px;
		border-right: 1px solid #dddddd;
	}
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		padding: 10px;
	}
`;

const ContentRight = styled.div`
	flex: 1;
	max-width: 410px;
	padding: 10px;
`;

const BlockGroup = styled.div`
	display: flex;
	flex-direction: row;
`;

const Block = styled.div`
	padding: 10px;
	flex: ${(props) => props.flex || 1};
`;

const AvatarBlock = styled.div`
	padding-left: 15px;
	padding-top: 0px;
	padding-right: 10px;
	border-left: 1px solid #dddddd;
	flex: 1;
`;

const TopBlock = styled.div`
	padding-left: 10px;
	padding-button: 10px;
	flex: ${(props) => props.flex || 1};
`;

const TextFieldLabel = styled.div`
	color: #000000;
	font-size: 13px;
	line-height: 1.5;
	font-weight: bold;
	margin-bottom: 7px;
`;

const TitleFieldLabel = styled.div`
	color: #333399;
	font-size: 14px;
	line-height: 1.5;
	font-weight: bold;
	margin-bottom: 10px;
`;
