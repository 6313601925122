/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useEffect } from "react";
import { Prompt } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";
import _ from "lodash";
import { history } from "_util/_helper";
import { toast } from "react-toastify";
import moment from "moment";
import Button from "@material-ui/core/Button";

import { messageConstants, generalConstants } from "_constants";
import { messageAction } from "actions";
import { SubmitButton, CancelButton, Loading } from "components/general";

import { Desktop } from "components/responsive";
import { AdminMenubar, AdminUserInfo, AdminSectionbar, AdminFooter } from "components/admin";
import { PageContainer, LeftContainer, RightContainer, ContentContainer } from "components/layout";

import editIcon from "resources/img/school_inner_pencil.svg";

const FROM_SCHOOL = "school";
export const MsgConfirmPage = (props) => {
	const { from, message, schoolKey, schoolVipOnly } = props.location.state;

	const subsidiaryList = useSelector(({ auth }) => auth.subsidiaryList);

	const { isFetching, submitMessageSuccess, updateMessageSuccess, loggedIn } = useSelector(({ message, auth }) => ({
		isFetching: message.isFetching,
		updateMessageSuccess: message.updateMessageSuccess,
		submitMessageSuccess: message.submitMessageSuccess,
		loggedIn: auth.loggedIn,
	}));

	const [isLoading, setIsLoading] = useState(false);

	const [isSaved, setIsSaved] = useState(false);
	const dispatch = useDispatch();

	const handleBack = () => {
		history.goBack();
	};

	const handleExit = () => {
		history.push(
			from === FROM_SCHOOL
				? {
						pathname: generalConstants.PATH.SCHOOL_DETAIL,
						state: { from: from, schoolKey: schoolKey },
				  }
				: generalConstants.PATH.MSG_MANAGEMENT
		);
	};

	useEffect(() => {
		setIsLoading(isFetching);
	}, [isFetching]);

	useEffect(() => {
		if (updateMessageSuccess) {
			if (!isSaved) {
				setIsSaved(true);
				return;
			}
			dispatch({ type: messageConstants.type.RESET_MSG_SUCCESS });
			toast.success("已更新訊息");
			history.push(
				from === FROM_SCHOOL
					? {
							pathname: generalConstants.PATH.SCHOOL_DETAIL,
							state: { from: from, schoolKey: schoolKey },
					  }
					: generalConstants.PATH.MSG_MANAGEMENT
			);
		}
	}, [updateMessageSuccess, isSaved, dispatch]);

	useEffect(() => {
		if (submitMessageSuccess) {
			if (!isSaved) {
				setIsSaved(true);
				return;
			}
			dispatch({ type: messageConstants.type.RESET_MSG_SUCCESS });
			toast.success("已建立訊息。");
			history.push(
				from === FROM_SCHOOL
					? {
							pathname: generalConstants.PATH.SCHOOL_DETAIL,
							state: { from: from, schoolKey: schoolKey },
					  }
					: generalConstants.PATH.MSG_MANAGEMENT
			);
		}
	}, [submitMessageSuccess, isSaved, dispatch]);

	const handleSubmit = () => {
		if (!message.infoType) {
			toast.error("請填寫資訊分類");
			return;
		}

		if (message.infoType === messageConstants.DATA.infoType.NEWS || message.infoType === messageConstants.DATA.infoType.EB) {
			if (!message.schoolId) {
				toast.error("請填寫學校");
				return;
			}
		}

		if (!message.title) {
			toast.error("請填寫標題");
			return;
		}

		if (message.key) {
			dispatch(messageAction.updateAdminMsg(message));
		} else {
			dispatch(messageAction.createAdminMsg(message));
		}
	};

	const checkOnLeave = (location, action) => {
		return action === "POP" || isSaved || !loggedIn ? true : "訊息並未儲存，確定離開？";
	};

	const getLocationPath = () => {
		const pathArray = [];

		if (from === FROM_SCHOOL) {
			if (subsidiaryList?.schoolList?.totalItems !== 1 && subsidiaryList?.schoolList?.items?.length !== 1) {
				pathArray.push({
					title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.SCHOOL_MANAGEMENT.key],
					isAction: true,
					target: generalConstants.PATH.SCHOOL_MANAGEMENT,
				});
			}
			pathArray.push({
				title: message?.schoolName,
				isAction: true,
				target: generalConstants.PATH.SCHOOL_DETAIL,
				stateObj: { schoolKey: schoolKey },
			});
		} else {
			pathArray.push({
				title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.MSG_MANAGEMENT.key],
				isAction: true,
				target: generalConstants.PATH.MSG_MANAGEMENT,
			});
		}
		pathArray.push({
			title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.MSG_CONFIRM.key],
			isAction: false,
			target: "",
		});

		return pathArray;
	};

	return (
		<React.Fragment>
			{!!isLoading && <Loading />}
			<Prompt message={checkOnLeave} />
			<PageContainer>
				<LeftContainer>
					<AdminMenubar
						section={from === FROM_SCHOOL ? generalConstants.NAV_TAB.SCHOOL_MANAGEMENT.key : generalConstants.NAV_TAB.MSG_MANAGEMENT.key}
					/>
				</LeftContainer>
				<RightContainer>
					<Desktop>
						<AdminUserInfo />
					</Desktop>
					<AdminSectionbar title={generalConstants.NAV_TAB.MSG_CONFIRM.key} locationPathArray={getLocationPath()} />
					<ContentContainer padding="16px 20px 17px">
						<Button style={{ float: "right" }} onClick={handleBack}>
							<img src={editIcon} alt="" style={{ paddingRight: "5px" }} />
							修改訊息
						</Button>
						<RowInBlock>
							<ContentTop>
								{message.type === messageConstants.DATA.type.NEWS && (
									<Block>
										<Subtitle>學校</Subtitle>
										<Semicolon>:</Semicolon>
										<OverviewContainer>
											{schoolVipOnly && (
												<VipIconView>
													<VipText>VIP</VipText>
												</VipIconView>
											)}
											<TextFieldLabel>{message.schoolName}</TextFieldLabel>
										</OverviewContainer>
									</Block>
								)}
								{message.targetAudience && schoolVipOnly && (
									<Block>
										<Subtitle>發送對象</Subtitle>
										<Semicolon>:</Semicolon>
										<TextFieldLabel>{messageConstants.Translate.targetAudience[message.targetAudience]}</TextFieldLabel>
									</Block>
								)}
								<Block>
									<Subtitle>發送時間</Subtitle>
									<Semicolon>:</Semicolon>
									<TextFieldLabel>
										{message.scheduleTime ? moment(message.scheduleTime).format("YYYY年MM月DD日 hh:mmA") : "即時"}
									</TextFieldLabel>
								</Block>
							</ContentTop>

							<ContentBottom>
								<Block>
									<Subtitle>標題</Subtitle>
									<Semicolon>:</Semicolon>
									<TextFieldLabel>
										{message.category ? `[${messageConstants.Translate.category[message.category]}]` : ""} {message.title}
									</TextFieldLabel>
								</Block>
								<Block>
									<Subtitle>内容</Subtitle>
									<Semicolon>:</Semicolon>
									<TextFieldLabel style={{ whiteSpace: "pre-wrap" }}>{message.detail}</TextFieldLabel>
								</Block>

								<React.Fragment>
									{!message.fullDay && (
										<Block>
											<Subtitle>日期及時間</Subtitle>
											<Semicolon>:</Semicolon>
											<TextFieldLabel>
												{message.startTime ? moment(message.startTime).format("YYYY年MM月DD日 hh:mmA") : ""} ~{" "}
												{message.endTime ? moment(message.endTime).format("YYYY年MM月DD日 hh:mmA") : ""}
											</TextFieldLabel>
										</Block>
									)}
									{message.fullDay && (
										<Block>
											<Subtitle>日期及時間</Subtitle>
											<Semicolon>:</Semicolon>
											<TextFieldLabel>
												{message.startTime ? moment(message.startTime).format("YYYY年MM月DD日") : ""} ~{" "}
												{message.endTime ? moment(message.endTime).format("YYYY年MM月DD日") : ""} (全日)
											</TextFieldLabel>
										</Block>
									)}

									<Block>
										<Subtitle>連結</Subtitle>
										<Semicolon>:</Semicolon>
										<RowInBlock>
											<TextFieldLabel>{message.url ? message.url : "-"}</TextFieldLabel>
										</RowInBlock>
									</Block>
								</React.Fragment>
							</ContentBottom>
						</RowInBlock>
						<Block marginTop={"10px"} style={{ justifyContent: "flex-end" }}>
							<CancelButton label={"取消"} style={{ marginRight: "15px" }} handleOnClick={handleExit} />
							<SubmitButton label={"提交"} handleOnClick={handleSubmit} />
						</Block>
					</ContentContainer>
					<AdminFooter></AdminFooter>
				</RightContainer>
			</PageContainer>
		</React.Fragment>
	);
};

MsgConfirmPage.propTypes = {
	location: PropTypes.object,
};

const ContentTop = styled.div`
	flex: 1;
	border-bottom: 2px solid #666666;
`;

const ContentBottom = styled.div`
	flex: 1;
	padding-top: 20px;
`;

const Block = styled.div`
	display: flex;
	margin-bottom: 25px;
	flex-direction: row;
`;

const RowInBlock = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.marginBottom || "10px"};
	flex-direction: column;
`;

const Subtitle = styled.div`
	flex: 0 0 100px;
	color: black;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;

	font-size: 13px;
	font-weight: bold;
	line-height: 1.15;
	text-align: left;
	color: #000000;
`;

const Semicolon = styled.div`
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.15;
	letter-spacing: normal;
	text-align: left;
	color: #000000;
	padding: 0 10px;
`;

const TextFieldLabel = styled.div`
	flex: 1;
	color: black;
	font-size: 13px;

	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.15;
	letter-spacing: normal;
	text-align: left;
	color: #333333;
`;

const OverviewContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const VipIconView = styled.div`
	display: flex;
	width: 32px;
	flex-direction: row;
	margin-right: 5px;
`;

const VipText = styled.div`
	font-size: 12;
	font-weight: bold;
	color: #333333;
	background-color: #c8ae63;
	padding-left: 5px;
	padding-right: 5px;
`;
