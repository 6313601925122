import axios from "axios";
import qs from "qs";

export const templateService = {
	fetchTemplate,
	createTemplate,
	getTemplateByKey,
	updateTemplate,
	deleteTemplate,
};

const httpOptions = {
	headers: {
		"Content-Type": "application/json",
	},
	withCredentials: true,
};

async function fetchTemplate(form) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/template`,
		...httpOptions,
		method: "GET",
		cache: false,
		params: form,
		paramsSerializer: {
			serialize: function (params) {
				return qs.stringify(params, { arrayFormat: "repeat" });
			},
		},
	})
		.then(handleResponse)
		.catch(handleError);
}
async function getTemplateByKey(key) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/template/${key}`,
		...httpOptions,
		method: "GET",
		cache: false,
	})
		.then(handleResponse)
		.catch(handleError);
}

async function createTemplate(data) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/template`,
		...httpOptions,
		data: data,
		method: "POST",
	})
		.then(handleResponse)
		.catch(handleError);
}

async function updateTemplate(data) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/template`,
		...httpOptions,
		data: data,
		method: "PUT",
	})
		.then(handleResponse)
		.catch(handleError);
}

async function deleteTemplate(data) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/template`,
		...httpOptions,
		data: data,
		method: "DELETE",
	})
		.then(handleResponse)
		.catch(handleError);
}

function handleError(err) {
	const msg = (err.response && err.response.data.message) || err.message || err;
	throw { error: msg };
}

function handleResponse(res) {
	return res.data;
}
