import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { miniWidth } from "components/responsive";

export const CancelButton = (props) => {
	const { label, handleOnClick, style } = props;

	return (
		<Button
			variant="contained"
			style={{
				padding: miniWidth() ? "8px 17px" : "8px 34px",
				borderRadius: 50,
				backgroundColor: "#ebebeb",
				borderColor: "#ebebeb",
				color: "#585858",
				whiteSpace: "nowrap",
				textAlign: "center",
				...style,
			}}
			onClick={() => {
				if (handleOnClick) {
					handleOnClick();
				}
			}}
		>
			{label}
		</Button>
	);
};

CancelButton.propTypes = {
	label: PropTypes.string,
	handleOnClick: PropTypes.func,
	style: PropTypes.object,
};
