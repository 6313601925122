import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import { messageConstants, generalConstants, MOBILE_MAX_WIDTH } from "_constants";
import { messageAction } from "actions";
import moment from "moment";

import ProgressiveRejectDebouncer from "_util/debouncers/ProgressiveRejectDebouncer";
import { history, getMsgStatus } from "_util/_helper";

import { Status, Pagination, RefreshButton } from "components/general";
import { StandardTextField } from "components/general/standard";
import { ArrowDropDown, ArrowDropUp, Done } from "@material-ui/icons";

import { Desktop } from "components/responsive";
import { AdminMenubar, AdminUserInfo, AdminSectionbar, AdminFooter } from "components/admin";
import {
	PageContainer,
	LeftContainer,
	RightContainer,
	ContentContainer,
	SearchBar,
	SearchBarLeft,
	SearchBarRight,
	PaginationContainer,
	TableContainer,
	TableHeaderRow,
	Table,
	TableRow,
	THButton,
	TableTh,
	TableTd,
} from "components/layout";

import { ReactComponent as NewMsgIcon } from "resources/img/newmsg.svg";

const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 10;
const FROM_MSG = "message";
export const MsgManagementPage = () => {
	const { msgList, currentPage, totalNumOfMsg, numOfPage, messageSearchWord, messageSortingKey, messageSortingDirection } = useSelector(
		({ message }) => ({
			msgList: message.navigationObject?.root?.msgList,
			currentPage: message.navigationObject?.root?.currentPage || 1,
			totalNumOfMsg: message.navigationObject?.root?.totalNumOfMsg || 0,
			numOfPage: message.navigationObject?.root?.numOfPage || 1,
			messageSearchWord: message.navigationObject?.root?.searchWord,
			messageSortingKey: message.navigationObject?.root?.sortingKey,
			messageSortingDirection: message.navigationObject?.root?.sortingDirection,
		})
	);
	const { schoolList, loggedIn } = useSelector(({ auth }) => ({
		schoolList: auth.subsidiaryList?.schoolList?.items,
		loggedIn: auth.loggedIn,
	}));

	const [searchWord, setSearchWord] = useState(messageSearchWord || "");
	const [sortingKey, setSortingKey] = useState(messageSortingKey || "lastUpdateTime");
	const [sortingDirection, setSortingDirection] = useState(messageSortingDirection || "DESC");

	const dispatch = useDispatch();
	const timeoutRef = useRef(null);
	const [debouncer] = useState(() => new ProgressiveRejectDebouncer(0.5 * 1000));

	useEffect(() => {
		if (loggedIn) {
			let form = {
				page: currentPage,
				limit: DEFAULT_LIMIT,
				sort: `${sortingKey}:${sortingDirection}`,
				includeSchool: true,
			};
			if (searchWord) {
				form = { ...form, msg: { title: searchWord } };
			}
			dispatch(messageAction.fetchMessages(form, "root"));
		}
	}, [loggedIn]);

	const handleSorting = (key) => {
		let direction = "ASC";
		if (sortingKey === key && sortingDirection === "ASC") {
			direction = "DESC";
		}
		setSortingKey(key);
		setSortingDirection(direction);
		let form = {
			page: DEFAULT_PAGE,
			limit: DEFAULT_LIMIT,
			sort: `${key}:${direction}`,
			includeSchool: true,
		};
		if (searchWord) {
			form = { ...form, msg: { title: searchWord } };
		}
		dispatch(messageAction.fetchMessages(form, "root"));
	};

	const handlePageChange = (pageNum) => {
		let form = {
			page: pageNum,
			limit: DEFAULT_LIMIT,
			sort: `${sortingKey}:${sortingDirection}`,
			includeSchool: true,
		};
		if (searchWord) {
			form = { ...form, msg: { title: searchWord } };
		}
		dispatch(messageAction.fetchMessages(form, "root"));
	};

	function resetTimeout() {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
	}
	const handleChange = ({ key, value }) => {
		const tempSearchWord = key === "searchWord" ? value : searchWord;
		if (key === "searchWord") {
			setSearchWord(value);
		}
		let form = {
			page: DEFAULT_PAGE,
			limit: DEFAULT_LIMIT,
			sort: `${sortingKey}:${sortingDirection}`,
			includeSchool: true,
		};
		if (tempSearchWord) {
			form = { ...form, msg: { title: tempSearchWord } };
		}
		if (!debouncer.token()) {
			resetTimeout();
			timeoutRef.current = setTimeout(() => {
				dispatch(messageAction.fetchMessages(form, "root"));
			}, 1000);
			return;
		}
		resetTimeout();
		dispatch(messageAction.fetchMessages(form, "root"));
	};

	const handleRefreshFilter = () => {
		setSearchWord("");
		setSortingKey("lastUpdateTime");
		setSortingDirection("DESC");
		let form = {
			page: DEFAULT_PAGE,
			limit: DEFAULT_LIMIT,
			sort: `lastUpdateTime:DESC`,
			includeSchool: true,
		};
		dispatch(messageAction.fetchMessages(form, "root"));
	};

	const renderTH = () => {
		return messageConstants.TABLE_COLUMN.map((column) => {
			return (
				<StyledTableTh className={column.key} key={`${column.key}_table_header`}>
					<THButton key={`${column.key}_table_header`} type="button" onClick={() => handleSorting(column.key)}>
						<div style={{ textAlign: column.key === "status" || column.key === "fired" ? "center" : "auto" }}>
							{column.value}
							{sortingKey === column.key ? (
								sortingDirection === "ASC" ? (
									<ArrowDropUp fontSize={"small"} style={{ verticalAlign: "middle" }} />
								) : (
									<ArrowDropDown fontSize={"small"} style={{ verticalAlign: "middle" }} />
								)
							) : null}
						</div>
					</THButton>
				</StyledTableTh>
			);
		});
	};
	const renderTableHeader = renderTH();

	const HandleRowOnClick = (item) => {
		history.push({ pathname: generalConstants.PATH.EDIT_MSG, state: { from: FROM_MSG, msgKey: item._key, school: item.school } });
	};

	const renderTD = () => {
		if (msgList?.length > 0) {
			return msgList?.map((msg, index) => {
				const tempObj = {};
				messageConstants.TABLE_COLUMN.forEach((column) => {
					tempObj[column.key] = msg[column.key];
				});
				return (
					<TableRow
						key={`${msg._id}_${index}_tr`}
						onClick={() => {
							HandleRowOnClick(msg);
						}}
					>
						{Object.keys(tempObj).map((columnName, index) => {
							if (columnName === "status") {
								return (
									<StyledTableTd className={columnName} key={`${msg._id}_${columnName}_${index}_td`} style={{ verticalAlign: "middle" }}>
										<Status status={getMsgStatus(msg)} />
									</StyledTableTd>
								);
							} else if (columnName === "type" || columnName === "infoType" || columnName === "targetAudience") {
								return (
									<StyledTableTd className={columnName} key={`${msg._id}_${columnName}_${index}_td`}>
										{messageConstants.Translate[columnName][tempObj[columnName]]}
									</StyledTableTd>
								);
							} else if (columnName === "title") {
								return (
									<StyledTableTd className={columnName} key={`${msg._id}_${columnName}_${index}_td`}>
										<TitleText>{tempObj[columnName]}</TitleText>
									</StyledTableTd>
								);
							} else if (columnName === "scheduleTime") {
								return (
									<StyledTableTd className={columnName} key={`${msg._id}_${columnName}_${index}_td`}>
										{tempObj[columnName] ? moment(tempObj[columnName]).format("YYYY年M月DD日 hh:mmA") : "-"}
									</StyledTableTd>
								);
							} else if (columnName === "fired") {
								return (
									<StyledTableTd className={columnName} key={`${msg._id}_${columnName}_${index}_td`} style={{ textAlign: "center" }}>
										{tempObj[columnName] ? <Done /> : null}
									</StyledTableTd>
								);
							} else if (columnName === "schoolName") {
								return (
									<TableTd key={`${msg._id}_${columnName}_${index}_td`}>
										<OverviewContainer>
											{msg?.school?.vipOnly && (
												<VipIconView>
													<VipText>VIP</VipText>
												</VipIconView>
											)}
											{tempObj[columnName]}
										</OverviewContainer>
									</TableTd>
								);
							}
							return (
								<StyledTableTd className={columnName} key={`${msg._id}_${columnName}_${index}_td`}>
									{tempObj[columnName]}
								</StyledTableTd>
							);
						})}
					</TableRow>
				);
			});
		}
	};
	const renderTableCell = renderTD();

	const handleNewMsg = () => {
		history.push(generalConstants.PATH.NEW_MSG, {
			from: FROM_MSG,
		});
	};

	return (
		<React.Fragment>
			<PageContainer>
				<LeftContainer>
					<AdminMenubar section={generalConstants.NAV_TAB.MSG_MANAGEMENT.key} />
				</LeftContainer>
				<RightContainer>
					<Desktop>
						<AdminUserInfo />
					</Desktop>
					<AdminSectionbar
						title={generalConstants.NAV_TAB.MSG_MANAGEMENT.key}
						buttonLabel={schoolList?.length > 0 ? "發送新訊息" : ""}
						buttonIcon={schoolList?.length > 0 ? <NewMsgIcon /> : <></>}
						buttonType={generalConstants.BUTTON_TYPE.SUBMIT}
						handleOnClick={handleNewMsg}
						sublabel={"訊息數量：" + totalNumOfMsg}
						locationPathArray={[
							{
								title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.MSG_MANAGEMENT.key],
								isAction: false,
								target: "",
							},
						]}
					/>
					<ContentContainer>
						<SearchBar>
							<SearchBarLeft>
								<RowInBlock marginBottom="0px">
									<div style={{ marginLeft: "10px", width: "200px" }}>
										<StandardTextField
											placeholder="訊息標題"
											value={searchWord}
											name="searchWord"
											handleChange={handleChange}
											style={{ borderTopRightRadius: "0px", borderBottomRightRadius: "0px" }}
										/>
									</div>
									<RefreshButton style={{ marginLeft: "5px" }} handleOnClick={handleRefreshFilter} label="重置選項"></RefreshButton>
								</RowInBlock>
							</SearchBarLeft>
							<SearchBarRight>
								<RowInBlock marginBottom="0px" style={{ float: "right" }}></RowInBlock>
							</SearchBarRight>
						</SearchBar>
						<TableContainer>
							<Table>
								<thead>
									<TableHeaderRow>{renderTableHeader}</TableHeaderRow>
								</thead>
								<tbody>{renderTableCell}</tbody>
							</Table>
						</TableContainer>
						<PaginationContainer>
							<Pagination count={numOfPage} page={currentPage} handlePageChange={handlePageChange} />
						</PaginationContainer>
					</ContentContainer>
					<AdminFooter></AdminFooter>
				</RightContainer>
			</PageContainer>
		</React.Fragment>
	);
};

const TitleText = styled.span`
	color: #0085b5;
	text-decoration: underline;
`;

const RowInBlock = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.marginBottom || "10px"};
	flex-direction: row;
`;

const StyledTableTh = styled(TableTh)`
	&.status {
		width: 70px;
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			left: 0;
			z-index: 2;
			position: sticky;
			width: 70px;
		}
	}
	&.targetAudience {
		width: 85px;
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			width: 85px;
		}
	}
	&.schoolName {
		width: 25%;
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			width: calc(30vw);
		}
	}
	&.title {
		width: auto;
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			width: calc(50vw);
		}
	}
	&.postBy {
		width: 20%;
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			width: 170px;
		}
	}
	&.fired {
		width: 8%;
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			width: calc(8vw);
		}
	}
	&.scheduleTime {
		width: 170px;
	}
`;

const StyledTableTd = styled(TableTd)`
	background-color: #fff;
	&.status {
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			left: 0;
			z-index: 2;
			position: sticky;
			width: 70px;
		}
	}
	&.schoolName {
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			left: 70px;
			z-index: 2;
			position: sticky;
			width: calc(30vw);
		}
	}
`;

const OverviewContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const VipIconView = styled.div`
	display: flex;
	width: 32px;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-right: 5px;
`;
const VipText = styled.div`
	font-size: 12;
	font-weight: bold;
	color: #333333;
	background-color: #c8ae63;
	padding-left: 5px;
	padding-right: 5px;
`;
