import React, { useState, useEffect } from "react";
import { Prompt } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import moment from "moment";
import PropTypes from "prop-types";
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { toast } from "react-toastify";
import Button from "@material-ui/core/Button";

import { useHistoryState } from "_util/hook";
import { history, getMsgStatus } from "_util/_helper";
import {
	Select,
	Checkbox,
	TextField,
	SubmitButton,
	CancelButton,
	CloneButton,
	Loading,
	DangerButton,
	AlertDialog,
	TemplateImportDialog,
	TemplateExportDialog,
} from "components/general";
import { messageConstants, generalConstants, statusConstants, DESKTOP_MIN_WIDTH, MOBILE_MAX_WIDTH } from "_constants";
import { messageAction } from "actions";
import { Desktop, Mobile, desktop } from "components/responsive";
import { AdminMenubar, AdminUserInfo, AdminSectionbar, AdminFooter } from "components/admin";
import { PageContainer, LeftContainer, RightContainer, ContentContainer } from "components/layout";

import Add from "@material-ui/icons/AddCircleOutline";
import Remove from "@material-ui/icons/RemoveCircleOutline";
import ImportIcon from "resources/img/import_template.svg";
import ExportIcon from "resources/img/export_template.svg";

const FROM_SCHOOL = "school";
export const EditMsgPage = (props) => {
	const { from, msgKey, schoolKey, school } = props.location.state;

	const getRoundedDate = (minutes, d = new Date()) => {
		let ms = 1000 * 60 * minutes;
		let roundedDate = new Date(Math.ceil(d.getTime() / ms) * ms);

		return roundedDate;
	};

	const isSameDate = (date1, date2) => {
		const tempDate1 = new Date(date1);
		const tempDate2 = new Date(date2);
		return (
			tempDate1.getFullYear() === tempDate2.getFullYear() &&
			tempDate1.getMonth() === tempDate2.getMonth() &&
			tempDate1.getDate() === tempDate2.getDate()
		);
	};

	const subsidiaryList = useSelector(({ auth }) => auth.subsidiaryList);

	const { isFetching, updateMessageSuccess, deleteMessageSuccess, message, loggedIn } = useSelector(({ message, auth }) => ({
		isFetching: message.isFetching,
		updateMessageSuccess: message.updateMessageSuccess,
		deleteMessageSuccess: message.deleteMessageSuccess,
		message: message.message,
		loggedIn: auth.loggedIn,
	}));

	const initForm = {
		type: messageConstants.OPTION.type[0].key,
		targetAudience: "",
		infoType: messageConstants.OPTION.infoType[0].key,
		schoolId: "",
		schoolName: "",
		scheduleType: messageConstants.OPTION.scheduleType[0].key,
		scheduleTime: getRoundedDate(5, new Date()),
		category: "",
		title: "",
		detail: "",
		startTime: getRoundedDate(30, new Date()),
		endTime: getRoundedDate(90, new Date()),
		eventType: messageConstants.DATA.eventType.SINGLE,
		fullDay: false,
		url: "",
	};

	const initExportData = {
		type: "adminmsg",
		category: "",
		title: "",
		detail: "",
	};

	const [formData, setFormData] = useHistoryState("formData", initForm);
	const [exportData, setExportData] = useState(initExportData);

	const [isLoading, setIsLoading] = useState(false);
	const [isChanged, setIsChanged] = useHistoryState("isChanged", false);

	const [isDeleted, setIsDeleted] = useState(false);
	const [isCloning, setIsCloning] = useState(false);

	const [isDeleteAlertShow, setIsDeleteAlertShow] = useState(false);
	const [isSubmitAlertShow, setIsSubmitAlertShow] = useState(false);

	const [isExportShow, setIsExportShow] = useState(false);
	const [isImportShow, setIsImportShow] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [status, setStatus] = useHistoryState("status", statusConstants.status.MSG_PENDING.key);

	const [addDateTimeIsClose, setAddDateTimeIsClose] = useHistoryState("addDateTimeIsClose", true);
	const [addURLIsClose, setAddURLIsClose] = useHistoryState("addURLIsClose", true);
	const [fullDay, setFullDay] = useHistoryState("fullDay", false);

	const dispatch = useDispatch();

	const handleBack = () => {
		history.goBack();
	};

	useEffect(() => {
		setIsLoading(isFetching);
	}, [isFetching]);

	useEffect(() => {
		if (history.action === "POP") return;
		dispatch(messageAction.getMessageByKey(msgKey));
	}, []);

	useEffect(() => {
		if (history.action === "POP") return;
		const tempForm = {};
		Object.keys(formData).forEach((key) => {
			if (key === "startTime") {
				if (message[key]) {
					setAddDateTimeIsClose(false);
				} else {
					setAddDateTimeIsClose(true);
				}
			}
			if (key === "fullDay") {
				setFullDay(message[key]);
			}
			tempForm[key] = message[key] ? message[key] : formData[key];
		});
		if (tempForm.url !== "") {
			setAddURLIsClose(false);
		} else {
			setAddURLIsClose(true);
		}

		if (moment().diff(moment(tempForm.scheduleTime), "second") < 0) {
			tempForm.scheduleType = messageConstants.OPTION.scheduleType[1].key;
		} else {
			tempForm.scheduleType = messageConstants.OPTION.scheduleType[0].key;
		}

		setStatus(getMsgStatus(message));
		setFormData(tempForm);
	}, [message]);

	useEffect(() => {
		if (updateMessageSuccess) {
			dispatch({ type: messageConstants.type.RESET_MSG_SUCCESS });
			toast.success("已更新訊息");
			history.goBack();
		}
	}, [updateMessageSuccess, dispatch]);

	useEffect(() => {
		if (deleteMessageSuccess) {
			if (!isDeleted) {
				setIsDeleted(true);
				return;
			}
			dispatch({ type: messageConstants.type.RESET_MSG_SUCCESS });
			toast.success("訊息已移除");
			history.goBack();
		}
	}, [deleteMessageSuccess, isDeleted, dispatch]);

	useEffect(() => {
		if (addDateTimeIsClose) {
			setFormData({
				...formData,
				startTime: getRoundedDate(30, new Date()),
				endTime: getRoundedDate(90, new Date()),
				eventType: messageConstants.DATA.eventType.SINGLE,
				fullDay: false,
			});
		}
	}, [addDateTimeIsClose]);

	const handleDelete = () => {
		const data = {
			key: message._key,
			rev: message._rev,
		};

		dispatch(messageAction.deleteAdminMsg(data));
	};

	const handleChange = ({ key, value }) => {
		if (key === "fullDay") {
			setFormData({ ...formData, [key]: !formData.fullDay });
		} else if (key === "scheduleTime") {
			const tempTime = new Date(value);
			setFormData({ ...formData, [key]: tempTime });
		} else if (key === "startTime" || key === "endTime") {
			const tempTime = new Date(value);
			let anotherKey = "";
			let anotherValue = null;
			let sameDate;

			if (key === "startTime") {
				anotherKey = "endTime";
				if (value > new Date(formData.endTime).getTime()) {
					anotherValue = tempTime;
				} else {
					anotherValue = formData.endTime;
				}
				sameDate = isSameDate(tempTime, anotherValue);
			} else {
				anotherKey = "startTime";
				if (value < new Date(formData.startTime).getTime()) {
					anotherValue = tempTime;
				} else {
					anotherValue = formData.startTime;
				}
				sameDate = isSameDate(tempTime, anotherValue);
			}
			setFormData({
				...formData,
				[key]: tempTime,
				[anotherKey]: anotherValue,
				eventType: sameDate ? messageConstants.DATA.eventType.SINGLE : messageConstants.DATA.eventType.PERIOD,
			});
		} else {
			setFormData({ ...formData, [key]: value });
		}
		setIsChanged(true);
	};

	const handleSubmit = () => {
		if (!formData.infoType) {
			toast.error("請填寫資訊分類");
			return;
		}
		if (
			formData.type === messageConstants.DATA.type.NEWS &&
			(formData.infoType === messageConstants.DATA.infoType.SCHOOL || formData.infoType === messageConstants.DATA.infoType.EB)
		) {
			if (!formData.schoolId) {
				toast.error("請填寫學校");
				return;
			}
		}

		if (!formData.title) {
			toast.error("請填寫標題");
			return;
		}

		if (!addDateTimeIsClose && !formData.startTime) {
			toast.error("請填寫開始日期");
			return;
		}
		if (!addDateTimeIsClose && !formData.endTime) {
			toast.error("請填寫結束日期");
			return;
		}
		if (!formData.targetAudience && !message.fired && school?.vipOnly) {
			toast.error("請填寫發送對象");
			return;
		}

		setIsSubmitting(true);
	};

	useEffect(() => {
		if (isSubmitting) {
			setIsSubmitting(false);
			let newStartTime;
			let newEndTime;
			if (formData.fullDay) {
				const startDate = new Date(formData.startTime);
				const endDate = new Date(formData.endTime);
				newStartTime = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0);
				newEndTime = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59);
			}
			var data = {
				key: message._key,
				rev: message._rev,
				type: formData.type,
				infoType: messageConstants.DATA.infoType.SCHOOL,
				schoolId: formData.schoolId,
				schoolName: formData.schoolName,
				scheduleTime: message.fired
					? new Date(message.scheduleTime)
					: formData.scheduleType === messageConstants.DATA.scheduleType.IMMEDIATELY
					? ""
					: new Date(formData.scheduleTime),
				category: formData.category,
				title: formData.title,
				detail: formData.detail,
				startTime: addDateTimeIsClose ? "" : formData.fullDay ? newStartTime : new Date(formData.startTime),
				endTime: addDateTimeIsClose ? "" : formData.fullDay ? newEndTime : new Date(formData.endTime),
				eventType: formData.eventType,
				fullDay: formData.fullDay,
				url: addURLIsClose ? "" : formData.url,
			};
			if (!message.fired && school?.vipOnly) {
				data = { ...data, targetAudience: formData.targetAudience };
			} else if (message.targetAudience) {
				data = { ...data, targetAudience: message.targetAudience };
			}
			history.push({
				pathname: from === FROM_SCHOOL ? `${generalConstants.PATH.SCHOOL_DETAIL}/message/confirm` : generalConstants.PATH.MSG_CONFIRM,
				state:
					from === FROM_SCHOOL
						? { from: from, schoolKey: school._key, message: data, schoolVipOnly: school?.vipOnly }
						: { from: from, message: data, schoolVipOnly: school?.vipOnly },
			});
		}
	}, [isSubmitting]);

	const handleCloseDeleteAlert = () => {
		setIsDeleteAlertShow(false);
	};
	const handleCloseSubmitAlert = () => {
		setIsSubmitAlertShow(false);
	};

	const handleCloseExport = () => {
		setIsExportShow(false);
	};

	const handleCloseImport = () => {
		setIsImportShow(false);
	};
	const handleOnExport = () => {
		if (!formData.title) {
			toast.error("請填寫標題");
			return;
		}

		setExportData({
			...exportData,
			category: formData.category,
			title: formData.title,
			detail: formData.detail,
		});

		setIsExportShow(true);
	};

	const handleOnImport = () => {
		setIsImportShow(true);
	};

	const handleImport = (importData) => {
		if (!importData) {
			toast.error("模板導入錯誤");
			return;
		}
		setFormData({
			...formData,
			//type: importData.type,
			category: importData.category,
			title: importData.title,
			detail: importData.detail,
		});
		toast.success("已成功導入模板");
		setIsImportShow(false);
	};

	const cloneAdminMsg = () => {
		setIsCloning(true);
	};

	useEffect(() => {
		if (isCloning) {
			let newStartTime;
			let newEndTime;
			if (formData.fullDay) {
				const startDate = new Date(formData.startTime);
				const endDate = new Date(formData.endTime);
				newStartTime = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0);
				newEndTime = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59);
			}
			const data = {
				type: formData.type,
				infoType: messageConstants.DATA.infoType.SCHOOL,
				targetAudience: formData.targetAudience,
				scheduleTime: message.fired
					? new Date(message.scheduleTime)
					: formData.scheduleType === messageConstants.DATA.scheduleType.IMMEDIATELY
					? ""
					: new Date(formData.scheduleTime),
				category: formData.category,
				title: formData.title,
				detail: formData.detail,
				startTime: addDateTimeIsClose ? "" : formData.fullDay ? newStartTime : new Date(formData.startTime),
				endTime: addDateTimeIsClose ? "" : formData.fullDay ? newEndTime : new Date(formData.endTime),
				eventType: formData.eventType,
				fullDay: formData.fullDay,
				url: addURLIsClose ? "" : formData.url,
				schoolId: message.schoolId || "",
				schoolName: message.schoolName || "",
				schoolVipOnly: school?.vipOnly || false,
			};
			directToNewMsg(data);
		}
	}, [isCloning]);

	const directToNewMsg = async (data) => {
		history.push({
			pathname: from === FROM_SCHOOL ? `${generalConstants.PATH.SCHOOL_DETAIL}/message/add` : generalConstants.PATH.NEW_MSG,
			state: from === FROM_SCHOOL ? { from: from, schoolKey: schoolKey, message: data } : { from: from, message: data },
		});
	};

	const getLocationPath = () => {
		const pathArray = [];

		if (from === FROM_SCHOOL) {
			if (subsidiaryList?.schoolList?.totalItems !== 1 && subsidiaryList?.schoolList?.items?.length !== 1) {
				pathArray.push({
					title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.SCHOOL_MANAGEMENT.key],
					isAction: true,
					target: generalConstants.PATH.SCHOOL_MANAGEMENT,
				});
			}
			pathArray.push({
				title: school.name[0] || school.name[1] || "",
				isAction: true,
				target: generalConstants.PATH.SCHOOL_DETAIL,
				stateObj: { schoolKey: schoolKey },
			});
		} else {
			pathArray.push({
				title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.MSG_MANAGEMENT.key],
				isAction: true,
				target: generalConstants.PATH.MSG_MANAGEMENT,
			});
		}
		pathArray.push({
			title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.EDIT_MSG.key],
			isAction: false,
			target: "",
		});

		return pathArray;
	};

	const sectionButton = () => {
		return (
			<>
				<Button style={{ float: "right" }} onClick={handleOnExport}>
					<img src={ExportIcon} alt="" style={{ paddingRight: "5px" }} />
					導出訊息模板
				</Button>
				{status === statusConstants.status.MSG_DELETED.key ? (
					<span />
				) : (
					<Button style={{ float: "right" }} onClick={handleOnImport}>
						<img src={ImportIcon} alt="" style={{ paddingRight: "5px" }} />
						導入訊息模板
					</Button>
				)}
			</>
		);
	};

	return (
		<React.Fragment>
			{!!isLoading && <Loading />}
			<AlertDialog active={isDeleteAlertShow} handleOnClose={handleCloseDeleteAlert} handleOnYes={handleDelete} title={"確定移除訊息？"} />
			<AlertDialog active={isSubmitAlertShow} handleOnClose={handleCloseSubmitAlert} handleOnYes={handleSubmit} title={"確定修改訊息？"} />
			<TemplateExportDialog active={isExportShow} handleOnClose={handleCloseExport} template={exportData}></TemplateExportDialog>
			<TemplateImportDialog active={isImportShow} handleOnClose={handleCloseImport} handleOnImport={handleImport}></TemplateImportDialog>
			<Prompt when={isChanged && !isCloning && !isSubmitting && !isDeleted && loggedIn} message="你有未儲存的變更，確定不儲存下離開？" />
			<PageContainer>
				<LeftContainer>
					<AdminMenubar
						section={from === FROM_SCHOOL ? generalConstants.NAV_TAB.SCHOOL_MANAGEMENT.key : generalConstants.NAV_TAB.MSG_MANAGEMENT.key}
					/>
				</LeftContainer>
				<RightContainer>
					<Desktop>
						<AdminUserInfo />
					</Desktop>
					<AdminSectionbar title={generalConstants.NAV_TAB.EDIT_MSG.key} locationPathArray={getLocationPath()} />
					<ContentContainer padding={"16px 20px 17px"}>
						<Mobile>{sectionButton()}</Mobile>
						<ContentColumnsContainer>
							<ContentLeft>
								<Block marginTop={"0px"}>
									<Title> - 狀態</Title>
									<TextFieldLabel>{statusConstants.status[status]?.label}</TextFieldLabel>
								</Block>
								{formData.type === messageConstants.DATA.type.NEWS && (
									<Block>
										<Title> - 學校</Title>

										<OverviewContainer>
											{school?.vipOnly && (
												<VipIconView>
													<VipText>VIP</VipText>
												</VipIconView>
											)}
											<SchoolNameLabel>{formData.schoolName}</SchoolNameLabel>
										</OverviewContainer>
									</Block>
								)}
								{(!(
									status === statusConstants.status.MSG_DELETED.key ||
									status === statusConstants.status.MSG_PROCESSING.key ||
									status === statusConstants.status.MSG_SUCCESS.key
								) ||
									message.targetAudience) &&
									school?.vipOnly && (
										<Block>
											<Title> - 發送對象</Title>
											{status === statusConstants.status.MSG_DELETED.key ||
											status === statusConstants.status.MSG_PROCESSING.key ||
											status === statusConstants.status.MSG_SUCCESS.key ? (
												<TextFieldLabel>{messageConstants.Translate.targetAudience[formData.targetAudience]}</TextFieldLabel>
											) : (
												<Select
													name={"targetAudience"}
													options={messageConstants.OPTION.targetAudience}
													value={formData.targetAudience}
													handleChange={handleChange}
												/>
											)}
										</Block>
									)}
								<Block>
									{status === statusConstants.status.MSG_DELETED.key || status === statusConstants.status.MSG_SUCCESS.key ? (
										<React.Fragment>
											<Title> - 發送時間</Title>
											<TextFieldLabel> {moment(formData.scheduleTime).format("YYYY年MM月DD日 hh:mmA")}</TextFieldLabel>
										</React.Fragment>
									) : (
										<React.Fragment>
											<Title> - 訊息發送時間</Title>
											<RowInBlock marginBottom={"0px"}>
												{messageConstants.OPTION.scheduleType.map((item) => {
													return (
														<Checkbox
															key={`${item.key}_checkbox`}
															name={"scheduleType"}
															label={item.value}
															checked={formData.scheduleType === item.key}
															value={item.key}
															handleChange={handleChange}
														/>
													);
												})}
											</RowInBlock>
										</React.Fragment>
									)}
									{formData.scheduleType === messageConstants.DATA.scheduleType.SCHEDULED && (
										<RowInBlock>
											<MuiPickersUtilsProvider utils={MomentUtils}>
												<KeyboardDatePicker
													margin="dense"
													id="date-picker-scheduleTime"
													label="日期"
													format="DD/MM/YYYY"
													value={formData.scheduleTime}
													onChange={(value) => {
														handleChange({ key: "scheduleTime", value });
													}}
													style={{ marginRight: "10px" }}
												/>
												<KeyboardTimePicker
													margin="dense"
													id="time-picker-scheduleTime"
													label="時間"
													value={formData.scheduleTime}
													onChange={(value) => {
														handleChange({ key: "scheduleTime", value });
													}}
												/>
											</MuiPickersUtilsProvider>
										</RowInBlock>
									)}
								</Block>
							</ContentLeft>
							<ContentRight>
								<RightBlock>
									<Desktop>{sectionButton()}</Desktop>
									<Title> - 訊息設定</Title>
									<Subtitle>標題</Subtitle>
									<RowInBlock>
										{status === statusConstants.status.MSG_DELETED.key ? (
											<TextFieldLabel>
												{formData.category ? `[${messageConstants.Translate.category[formData.category]}]` : ""} {formData.title}
											</TextFieldLabel>
										) : (
											<React.Fragment>
												<div style={{ flex: 2, marginRight: "10px" }}>
													<Select
														displayEmpty
														name={"category"}
														options={messageConstants.OPTION.category}
														value={formData.category}
														handleChange={handleChange}
													/>
												</div>
												<div style={{ flex: 7 }}>
													<TextField placeholder="標題" value={formData.title} name="title" handleChange={handleChange} />
												</div>
											</React.Fragment>
										)}
									</RowInBlock>
									<Subtitle>内容</Subtitle>
									<RowInBlock>
										{status === statusConstants.status.MSG_DELETED.key ? (
											<TextFieldLabel style={{ whiteSpace: "pre-wrap" }}>{formData.detail}</TextFieldLabel>
										) : (
											<TextField placeholder="內容" multiline rows={6} value={formData.detail} name="detail" handleChange={handleChange} />
										)}
									</RowInBlock>
								</RightBlock>
								{status === statusConstants.status.MSG_DELETED.key ? (
									<React.Fragment>
										{!addDateTimeIsClose && !fullDay && (
											<React.Fragment>
												<Subtitle>開始日期</Subtitle>
												<RowInBlock>
													<TextFieldLabel>{moment(formData.startTime).format("YYYY年MM月DD日 hh:mmA")}</TextFieldLabel>
												</RowInBlock>
												<Subtitle>結束日期</Subtitle>
												<RowInBlock>
													<TextFieldLabel>{moment(formData.endTime).format("YYYY年MM月DD日 hh:mmA")}</TextFieldLabel>
												</RowInBlock>
											</React.Fragment>
										)}
										{!addDateTimeIsClose && fullDay && (
											<React.Fragment>
												<Subtitle>全日</Subtitle>
												<Subtitle>開始日期</Subtitle>
												<RowInBlock>
													<TextFieldLabel>{moment(formData.startTime).format("YYYY年MM月DD日")}</TextFieldLabel>
												</RowInBlock>
												<Subtitle>結束日期</Subtitle>
												<RowInBlock>
													<TextFieldLabel>{moment(formData.endTime).format("YYYY年MM月DD日")}</TextFieldLabel>
												</RowInBlock>
											</React.Fragment>
										)}
										{!addURLIsClose && (
											<React.Fragment>
												<Subtitle>連結</Subtitle>
												<RowInBlock>
													<TextFieldLabel>{formData.url}</TextFieldLabel>
												</RowInBlock>
											</React.Fragment>
										)}
									</React.Fragment>
								) : (
									<React.Fragment>
										<Block>
											<Button
												variant="text"
												startIcon={addDateTimeIsClose ? <Add /> : <Remove />}
												onClick={() => {
													setIsChanged(true);
													setAddDateTimeIsClose(!addDateTimeIsClose);
												}}
											>
												新增日期及時間
											</Button>
										</Block>
										{!addDateTimeIsClose && (
											<Block style={{ marginTop: "10px", marginLeft: "30px" }}>
												<Checkbox
													key={`fullDay_checkbox`}
													name={"fullDay"}
													label={"全日"}
													checked={formData.fullDay}
													handleChange={handleChange}
												/>
												<Subtitle marginBottom={"0px"}>開始日期</Subtitle>
												<RowInBlock>
													<MuiPickersUtilsProvider utils={MomentUtils}>
														<KeyboardDatePicker
															margin="dense"
															id="date-picker-startTime"
															label="日期"
															format="DD/MM/YYYY"
															value={formData.startTime}
															onChange={(value) => {
																handleChange({ key: "startTime", value });
															}}
															style={{ marginRight: "10px" }}
														/>
														<KeyboardTimePicker
															disabled={formData.fullDay}
															style={formData.fullDay ? { display: "none" } : null}
															margin="dense"
															id="time-picker-startTime"
															label="時間"
															value={formData.startTime}
															onChange={(value) => {
																handleChange({ key: "startTime", value });
															}}
														/>
													</MuiPickersUtilsProvider>
												</RowInBlock>
												<Subtitle marginBottom={"0px"}>結束日期</Subtitle>
												<RowInBlock>
													<MuiPickersUtilsProvider utils={MomentUtils}>
														<KeyboardDatePicker
															// disabled={formData.fullDay}
															margin="dense"
															id="date-picker-endTime"
															label="日期"
															format="DD/MM/YYYY"
															value={formData.endTime}
															onChange={(value) => {
																handleChange({ key: "endTime", value });
															}}
															style={{ marginRight: "10px" }}
														/>
														<KeyboardTimePicker
															disabled={formData.fullDay}
															style={formData.fullDay ? { display: "none" } : null}
															margin="dense"
															id="time-picker-endTime"
															label="時間"
															value={formData.endTime}
															onChange={(value) => {
																handleChange({ key: "endTime", value });
															}}
														/>
													</MuiPickersUtilsProvider>
												</RowInBlock>
											</Block>
										)}
										<Block marginTop={"10px"}>
											<Button
												variant="text"
												startIcon={addURLIsClose ? <Add /> : <Remove />}
												onClick={() => {
													setIsChanged(true);
													setAddURLIsClose(!addURLIsClose);
												}}
											>
												新增連結
											</Button>
										</Block>
										{!addURLIsClose && (
											<Block style={{ marginTop: "10px", marginLeft: "30px" }}>
												<TextField placeholder="連結" value={formData.url} name="url" handleChange={handleChange} />
											</Block>
										)}
									</React.Fragment>
								)}
							</ContentRight>
						</ContentColumnsContainer>
						<RowInBlock style={{ justifyContent: "flex-end", marginTop: "10px" }}>
							<CancelButton label={"返回"} style={{ marginRight: "15px" }} handleOnClick={handleBack} />
							{status !== statusConstants.status.MSG_DELETED.key && (
								<DangerButton
									label={"移除"}
									style={{ marginRight: "15px" }}
									handleOnClick={() => {
										setIsDeleteAlertShow(true);
									}}
								/>
							)}
							<CloneButton
								style={{ marginRight: "15px" }}
								label={"複製訊息"}
								handleOnClick={() => {
									cloneAdminMsg();
								}}
							/>
							{status !== statusConstants.status.MSG_DELETED.key && (
								<SubmitButton label={"提交"} handleOnClick={handleSubmit} active={isChanged} />
							)}
						</RowInBlock>
					</ContentContainer>
					<AdminFooter></AdminFooter>
				</RightContainer>
			</PageContainer>
		</React.Fragment>
	);
};

EditMsgPage.propTypes = {
	location: PropTypes.object,
};

const ContentLeft = styled.div`
	flex: 0 0 400px;
	@media (min-width: ${DESKTOP_MIN_WIDTH}) {
		padding-right: 20px;
		border-right: 1px solid #dddddd;
		min-height: 600px;
	}
`;

const ContentColumnsContainer = styled.div`
	@media (min-width: ${DESKTOP_MIN_WIDTH}) {
		flex-direction: row;
		display: flex;
	}
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		margin-bottom: 20px;
	}
`;

const ContentRight = styled.div`
	flex: 1;
	@media (min-width: ${DESKTOP_MIN_WIDTH}) {
		padding-left: 20px;
	}
`;

const Block = styled.div`
	margin-top: ${(props) => props.marginTop || "40px"};
	flex-direction: column;
`;

const RightBlock = styled.div`
	margin-top: 0px;
	flex-direction: column;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		margin-top: 40px;
	}
`;

const RowInBlock = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.marginBottom || "10px"};
	flex-direction: row;
`;

const Title = styled.div`
	font-weight: bold;
	margin: 0 0 12px 4px;
	font-size: 14px;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
	color: #333399;
`;

const Subtitle = styled.div`
	color: black;
	margin-bottom: ${(props) => props.marginBottom || "10px"};
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
`;

const TextFieldLabel = styled.div`
	color: black;
	font-size: 13px;
	line-height: 1.5;
	font-weight: bold;
	margin-bottom: 40px;
`;

const OverviewContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const VipIconView = styled.div`
	display: flex;
	width: 32px;
	flex-direction: row;
	margin-right: 5px;
`;

const VipText = styled.div`
	font-size: 12;
	font-weight: bold;
	color: #333333;
	background-color: #c8ae63;
	padding-left: 5px;
	padding-right: 5px;
`;

const SchoolNameLabel = styled.div`
	color: black;
	font-size: 13px;
	line-height: 1.5;
	font-weight: bold;
`;
