import { messageConstants, generalConstants } from "_constants";
import { messageService } from "services";
import { toast } from "react-toastify";
import { history } from "_util/_helper";
import _ from "lodash";

export const messageAction = {
	fetchMessages,
	getMessageByKey,
	createAdminMsg,
	updateAdminMsg,
	deleteAdminMsg,
};

function fetchMessages(form, navigationKey) {
	return (dispatch) => {
		messageService.fetchMessages(form).then(
			(response) => {
				dispatch(success(response));
			},
			(error) => {
				console.error("fetchMessage ERROR:", error);
				toast.error(error.error);
			}
		);
	};
	function success(response) {
		const { totalItems, items } = response;
		let result = {
			navigationKey,
			type: messageConstants.type.FETCH_MSG_LIST_SUCCESS,
			totalNum: totalItems,
			totalPage: Math.ceil(totalItems / form.limit),
			currentPage: form.page,
			msgList: items,
		};
		let sortingKey = "lastUpdateTime",
			sortingDirection = "DESC";
		const sortingArray = form?.sort?.split(":");
		if (sortingArray && sortingArray.length === 2 && sortingArray[0] && sortingArray[1]) {
			sortingKey = sortingArray[0];
			sortingDirection = sortingArray[1] === "DESC" ? "DESC" : "ASC";
			result = { ...result, sortingKey, sortingDirection };
		}
		if (typeof form.msg?.title != "undefined") {
			result = {
				...result,
				searchKey: typeof form.msg?.title != "undefined" ? messageConstants.MSG_FILTER.TITLE : "",
				searchWord: typeof form.msg?.title != "undefined" ? form.msg?.title : "",
			};
		}
		if (typeof form.school?.level != "undefined") {
			result = { ...result, level: form.school?.level };
		}
		if (typeof form.msg?.dateRange != "undefined") {
			result = { ...result, dateRange: form.msg?.dateRange };
		}
		return result;
	}
}

function getMessageByKey(key) {
	return (dispatch) => {
		dispatch(request());
		messageService.getMessageByKey(key).then(
			(response) => {
				dispatch(success(response));
			},
			(error) => {
				console.error("getMessage ERROR:", error);
				toast.error(error.error);
				dispatch(failure());
				if (error.error?.status !== 401) {
					history.push(generalConstants.PATH.MSG_MANAGEMENT);
				}
			}
		);
	};

	function request() {
		return { type: messageConstants.type.FETCH_MSG_REQUEST };
	}
	function failure() {
		return { type: messageConstants.type.FETCH_MSG_FAILURE };
	}
	function success(message) {
		return {
			type: messageConstants.type.FETCH_MSG_SUCCESS,
			message,
		};
	}
}

function createAdminMsg(data) {
	return (dispatch) => {
		dispatch(request());
		messageService.createAdminMsg(data).then(
			() => {
				dispatch(success());
			},
			(error) => {
				console.error("createAdminMsg ERROR:", error);
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: messageConstants.type.SUBMIT_MSG_REQUEST };
	}
	function failure() {
		return { type: messageConstants.type.SUBMIT_MSG_FAILURE };
	}
	function success() {
		return { type: messageConstants.type.SUBMIT_MSG_SUCCESS };
	}
}

function updateAdminMsg(data) {
	return (dispatch) => {
		dispatch(request());
		messageService.updateAdminMsg(data).then(
			() => {
				dispatch(success());
			},
			(error) => {
				console.error("updateAdminMsg ERROR:", error);
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: messageConstants.type.UPDATE_MSG_REQUEST };
	}
	function failure() {
		return { type: messageConstants.type.UPDATE_MSG_FAILURE };
	}
	function success() {
		return { type: messageConstants.type.UPDATE_MSG_SUCCESS };
	}
}

function deleteAdminMsg(data) {
	return (dispatch) => {
		dispatch(request());
		messageService.deleteAdminMsg(data).then(
			() => {
				dispatch(success());
			},
			(error) => {
				console.error("deleteAdminMsg ERROR:", error);
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: messageConstants.type.DELETE_MSG_REQUEST };
	}
	function failure() {
		return { type: messageConstants.type.DELETE_MSG_FAILURE };
	}
	function success() {
		return { type: messageConstants.type.DELETE_MSG_SUCCESS };
	}
}
