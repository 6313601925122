import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import backgroundImageWeb from "resources/img/cover_web.jpg";
import backgroundImageMobile from "resources/img/cover_mobile.jpg";
import { MOBILE_MAX_WIDTH } from "_constants";

import { AuthContainer, ForgetPasswordForm } from "components/auth";
import { Loading } from "components/general";
import { authAction } from "actions";

export const LoginPage = () => {
	const dispatch = useDispatch();

	const isFetching = useSelector(({ auth }) => auth.isFetching);
	const isLoginFetching = useSelector(({ auth }) => auth.isLoginFetching);
	const forgetPasswordSuccess = useSelector(({ auth }) => auth.forgetPasswordSuccess);

	const [showForgetPassword, setShowForgetPassword] = useState(false);
	const [isSubmitting, setSubmitting] = useState(false);

	const handleLogin = (form) => {
		dispatch(authAction.loginWithFirebase(form));
	};

	const handleForgetPassword = (data) => {
		dispatch(authAction.forgetPassword(data));
	};

	useEffect(() => {
		if (!isFetching && forgetPasswordSuccess) {
			setShowForgetPassword(false);
		}
	}, [isFetching, forgetPasswordSuccess]);

	const openPopup = () => {
		setShowForgetPassword(true);
	};

	const handleCloseDetailModal = () => {
		setShowForgetPassword(false);
	};

	return (
		<Container>
			{(!!isLoginFetching || !!isFetching || !!isSubmitting) && <Loading />}
			<AuthContainer
				showForgetPassword={showForgetPassword}
				isSubmitting={isSubmitting}
				setSubmitting={setSubmitting}
				handleLogin={handleLogin}
				handleForgetPassword={openPopup}
			/>
			<BackgroundImage />
			<ForgetPasswordForm open={showForgetPassword} onClose={handleCloseDetailModal} handleForgetPassword={handleForgetPassword} />
		</Container>
	);
};

const Container = styled.div`
	height: 100vh;
	width: 100vw;
`;

const BackgroundImage = styled.div`
	height: 100%;
	width: 100%;
	position: fixed;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url(${backgroundImageWeb});
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		background-image: url(${backgroundImageMobile});
	}
`;
