import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import { schoolConstants, generalConstants, MOBILE_MAX_WIDTH } from "_constants";
import { schoolAction } from "actions";
import isEqual from "react-fast-compare";

import { history } from "_util/_helper";

import { Pagination } from "components/general";

import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";

import { Desktop } from "components/responsive";
import { AdminMenubar, AdminUserInfo, AdminSectionbar, AdminFooter } from "components/admin";
import {
	PageContainer,
	LeftContainer,
	RightContainer,
	ContentContainer,
	PaginationContainer,
	TableContainer,
	TableHeaderRow,
	Table,
	TableRow,
	THButton,
	TableTh,
	TableTd,
} from "components/layout";

const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 10;

const initSearchForm = {
	level: "",
	district: "",
	gender: "",
	schoolNet: "",
	name: "",
	page: DEFAULT_PAGE,
	limit: DEFAULT_LIMIT,
};

export const SchoolManagementPage = () => {
	const { schoolList, currentPage, numOfPage, totalNumOfSchool, schoolSortingKey, schoolSortingDirection } = useSelector(
		({ school }) => ({
			schoolList: school.schoolList,
			currentPage: school.currentPage || 1,
			numOfPage: school.numOfPage || 1,
			totalNumOfSchool: school.totalNumOfSchool || 0,
			schoolSortingKey: school.sortingKey,
			schoolSortingDirection: school.sortingDirection,
		}),
		isEqual
	);
	const loggedIn = useSelector(({ auth }) => auth.loggedIn);

	const searchFilter = {
		page: currentPage,
		limit: DEFAULT_LIMIT,
		sort: schoolSortingKey && schoolSortingDirection ? `${schoolSortingKey}:${schoolSortingDirection}` : "",
	};

	const [sortingKey, setSortingKey] = useState(schoolSortingKey);
	const [sortingDirection, setSortingDirection] = useState(schoolSortingDirection);

	const [searchForm, setSearchForm] = useState(searchFilter || initSearchForm);

	const dispatch = useDispatch();

	useEffect(() => {
		if (searchForm && loggedIn) {
			let form = { page: searchForm.page, limit: searchForm.limit };
			if (searchForm.level) {
				form = { ...form, level: searchForm.level };
			}
			if (searchForm.district) {
				form = { ...form, district: searchForm.district };
			}
			if (searchForm.gender) {
				form = { ...form, gender: searchForm.gender };
			}
			if (searchForm.schoolNet) {
				form = { ...form, schoolNet: searchForm.schoolNet };
			}
			if (searchForm.name) {
				form = { ...form, name: searchForm.name };
			}
			if (searchForm.sort) {
				const sortObj = searchForm.sort.split(":");
				if (sortObj[0] && sortObj[1]) {
					form = { ...form, sort: searchForm.sort };
				}
			}
			dispatch(schoolAction.getSchoolList(form, "root"));
		}
	}, [dispatch, loggedIn, searchForm]);

	useEffect(() => {
		if (schoolList && schoolList?.length === 1) {
			history.push({
				pathname: generalConstants.PATH.SCHOOL_DETAIL,
				state: { schoolKey: schoolList[0]._key },
			});
		}
	}, [schoolList]);

	const HandleRowOnClick = (item) => {
		history.push({
			pathname: generalConstants.PATH.SCHOOL_DETAIL,
			state: {
				schoolKey: item._key,
			},
		});
	};

	const handlePageChange = (pageNum) => {
		setSearchForm({ ...searchForm, page: pageNum });
	};

	const handleSortAction = (sort) => {
		let direction = "DESC";
		if (sortingKey === sort && sortingDirection === "DESC") {
			direction = "ASC";
		}
		setSortingKey(sort);
		setSortingDirection(direction);
		setSearchForm({ ...searchForm, page: DEFAULT_PAGE, sort: `${sort}:${direction}` });
	};

	const renderTH = () => {
		return schoolConstants.TABLE_COLUMN.map((column) => {
			return (
				<StyledTableTh className={column.key} key={`${column.key}_table_header`}>
					<THButton
						style={
							column.key === "overallSubscription" || column.key === "currentYearSubscription" ? { borderLeft: "#666666 1px solid" } : {}
						}
						key={`${column.key}_table_header`}
						type="button"
						onClick={() => handleSortAction(column.key)}
					>
						<div style={{ textAlign: column.key === "status" ? "center" : "auto" }}>
							{column.key === "vipOnly" ? (
								<VipIconView>
									<VipText>{column.value}</VipText>
									{sortingKey === column.key ? (
										sortingDirection === "ASC" ? (
											<ArrowDropUp fontSize={"small"} style={{ verticalAlign: "middle" }} />
										) : (
											<ArrowDropDown fontSize={"small"} style={{ verticalAlign: "middle" }} />
										)
									) : null}
								</VipIconView>
							) : (
								column.value
							)}
							{sortingKey === column.key && column.key !== "vipOnly" ? (
								sortingDirection === "ASC" ? (
									<ArrowDropUp fontSize={"small"} style={{ verticalAlign: "middle" }} />
								) : (
									<ArrowDropDown fontSize={"small"} style={{ verticalAlign: "middle" }} />
								)
							) : null}
						</div>
					</THButton>
				</StyledTableTh>
			);
		});
	};
	const renderTableHeader = renderTH();

	const renderTD = () => {
		if (schoolList?.length > 0) {
			return schoolList?.map((item, index) => {
				if (index < DEFAULT_LIMIT * (searchFilter.tablePage - 1) || index > DEFAULT_LIMIT * searchFilter.tablePage - 1) {
					return null;
				}
				const tempObj = {};
				schoolConstants.TABLE_COLUMN.forEach((column) => {
					tempObj[column.key] = item[column.key];
				});
				return (
					<TableRow
						key={`${item._id}_${index}_tr`}
						onClick={() => {
							HandleRowOnClick(item);
						}}
					>
						{Object.keys(tempObj).map((columnName, index) => {
							if (tempObj[columnName] || tempObj[columnName] === 0) {
								if (columnName === "name") {
									return (
										<StyledTableTd className={columnName} key={`${item._id}_${columnName}_${index}_td`}>
											{item.status === schoolConstants.status.CLOSED.key && <ClosedBadge>已停辦</ClosedBadge>}
											{tempObj[columnName][0]}
										</StyledTableTd>
									);
								} else if (columnName === "overallSubscription" || columnName === "currentYearSubscription") {
									return (
										<StyledTableTd
											className={columnName}
											style={{ borderLeft: "#dddddd 1px solid" }}
											key={`${item._id}_${columnName}_${index}_td`}
										>
											{tempObj[columnName]}
										</StyledTableTd>
									);
								} else if (columnName === "numMsg") {
									return (
										<StyledTableTd className={columnName} key={`${item._id}_${columnName}_${index}_td`}>
											{tempObj[columnName]}
										</StyledTableTd>
									);
								} else if (columnName === "overallRank" || columnName === "currentYearRank") {
									return (
										<StyledTableTd className={columnName} key={`${item._id}_${columnName}_${index}_td`}>
											{tempObj[columnName]}
										</StyledTableTd>
									);
								} else if (columnName === "vipOnly") {
									return (
										<TableTd key={`${item._id}_${columnName}_${index}_td`}>
											{tempObj[columnName] && (
												<VipIconView>
													<VipText>VIP</VipText>
												</VipIconView>
											)}
										</TableTd>
									);
								} else {
									return (
										<StyledTableTd className={columnName} key={`${item._id}_${columnName}_${index}_td`}>
											{schoolConstants[columnName][tempObj[columnName]] ? schoolConstants[columnName][tempObj[columnName]].label : ""}
										</StyledTableTd>
									);
								}
							} else {
								return (
									<StyledTableTd className={columnName} key={`${item._id}_${columnName}_${index}_td`}>
										{columnName === "vipOnly" ? "" : "-"}
									</StyledTableTd>
								);
							}
						})}
					</TableRow>
				);
			});
		}
	};
	const renderTableCell = renderTD();

	return (
		<React.Fragment>
			<PageContainer>
				<LeftContainer>
					<AdminMenubar section={generalConstants.NAV_TAB.SCHOOL_MANAGEMENT.key} />
				</LeftContainer>
				<RightContainer>
					<Desktop>
						<AdminUserInfo />
					</Desktop>
					<AdminSectionbar
						title={generalConstants.NAV_TAB.SCHOOL_MANAGEMENT.key}
						sublabel={"學校數量：" + totalNumOfSchool}
						locationPathArray={[
							{
								title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.SCHOOL_MANAGEMENT.key],
								isAction: false,
								target: "",
							},
						]}
					/>
					<ContentContainer>
						<TableContainer>
							<Table>
								<thead>
									<TableHeaderRow>{renderTableHeader}</TableHeaderRow>
								</thead>
								<tbody>{renderTableCell}</tbody>
							</Table>
						</TableContainer>
						<PaginationContainer style={{ paddingLeft: "20px" }}>
							<Pagination count={numOfPage} page={currentPage} handlePageChange={handlePageChange} />
						</PaginationContainer>
					</ContentContainer>
					<AdminFooter></AdminFooter>
				</RightContainer>
			</PageContainer>
		</React.Fragment>
	);
};

const ClosedBadge = styled.span`
	padding: 2px 6px 3px;
	border-radius: 3px;
	background-color: #fff0f2;
	margin: 9px 6px 9px 0;
	font-size: 10px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #ff0000;
`;

const StyledTableTh = styled(TableTh)`
	&.vipOnly {
		width: 5%;
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			width: calc(20vw);
		}
	}
	&.name {
		width: 40%;
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			left: 0;
			z-index: 2;
			position: sticky;
			width: calc(40vw);
		}
	}
	&.level {
		width: 10%;
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			width: calc(20vw);
		}
	}
	&.district {
		width: 10%;
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			width: calc(20vw);
		}
	}
	&.schoolNet {
		width: 10%;
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			width: calc(20vw);
		}
	}
	&.gender {
		width: 10%;
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			width: calc(20vw);
		}
	}
	&.category {
		width: 20%;
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			width: calc(30vw);
		}
	}
	&.numMsg {
		width: 10%;
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			width: calc(15vw);
		}
	}
`;

const StyledTableTd = styled(TableTd)`
	background-color: #fff;
	&.name {
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			left: 0;
			z-index: 2;
			position: sticky;
			width: calc(40vw);
		}
	}
`;

const VipIconView = styled.div`
	display: flex;
	width: 32px;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-left: 5px;
`;
const VipText = styled.div`
	font-size: 12;
	font-weight: bold;
	color: #333333;
	background-color: #c8ae63;
	padding-left: 5px;
	padding-right: 5px;
`;
