import React, { useEffect, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import _ from "lodash";
import axios from "axios";
import { setupCache, buildKeyGenerator, buildWebStorage } from "axios-cache-interceptor";

import {
	NewMsgPage,
	MsgManagementPage,
	EditMsgPage,
	MsgConfirmPage,
	SchoolManagementPage,
	SchoolDetailScreen,
	EditSchoolPage,
	ChangePasswordPage,
	EditOrganizationPage,
	LoginPage,
	RegisterPage,
	EmailLandingPage,
} from "./Page";

import { history } from "_util/_helper";

import { PrivateRoute, Loading } from "components/general";
import { generalConstants } from "_constants";
import { authAction } from "actions";
import { app } from "_util/firebase";
function App() {
	const user = useSelector(({ auth }) => auth.user);
	const loggedIn = useSelector(({ auth }) => auth.loggedIn);
	const subsidiaryList = useSelector(({ auth }) => auth.subsidiaryList);

	const isFetching = useSelector(({ auth }) => auth.isFetching);
	const isLogoutFetching = useSelector(({ auth }) => auth.isLogoutFetching);

	const dispatch = useDispatch();

	const [isReload, setReload] = useState(true);

	const onTokenExpired = useCallback(() => {
		setReload(true);
		dispatch(authAction.kickout());
	}, [dispatch]);

	const throttleOnTokenExpired = useCallback(_.throttle(onTokenExpired, 1000, { trailing: false }), []);

	useEffect(() => {
		return history.listen((location, action) => {
			if (
				action === "POP" &&
				!loggedIn &&
				location.pathname !== generalConstants.PATH.LOGIN &&
				location.pathname !== generalConstants.PATH.REGISTER &&
				location.pathname !== generalConstants.PATH.AUTH
			) {
				setReload(true);
				toast.error("連接逾時，請重新登入");
				history.push(generalConstants.PATH.LOGIN);
			}
		});
	}, [loggedIn]);

	useEffect(() => {
		setupCache(axios, {
			keyGenerator: buildKeyGenerator((request) => ({
				method: request.method,
				url: request.url,
			})),
			cache: {
				ttl: 30 * 60 * 1000,
			},
			storage: buildWebStorage(localStorage, "axios-cache:"),
		});
		axios.interceptors.response.use(
			(response) => {
				return response;
			},
			async (error) => {
				if (error.response?.status === 401) {
					window.localStorage.clear();
					throttleOnTokenExpired();
				}
				return Promise.reject(error);
			}
		);
	}, [onTokenExpired]);

	useEffect(() => {
		if (loggedIn && user && isReload) {
			setReload(false);
			dispatch(authAction.getOrganization(true, true));
		} else if (!loggedIn && _.isEmpty(user)) {
			setReload(true);
		}
	}, [dispatch, isReload, loggedIn, user]);

	return (
		<div className="App">
			{!!isLogoutFetching && <Loading />}
			<Router history={history}>
				<Switch>
					<Route exact path={generalConstants.PATH.AUTH} render={() => <EmailLandingPage />} />
					<Route
						exact
						path="/"
						render={() =>
							!!loggedIn && user && !isReload && !isFetching ? (
								<Redirect
									to={
										subsidiaryList?.schoolList?.totalItems === 1 && subsidiaryList?.schoolList?.items?.length === 1
											? { pathname: generalConstants.PATH.SCHOOL_DETAIL, state: { schoolKey: subsidiaryList?.schoolList?.items[0]._key } }
											: subsidiaryList?.schoolList?.totalItems === 0 && subsidiaryList?.schoolList?.items?.length === 0
											? generalConstants.PATH.SCHOOL_MANAGEMENT
											: generalConstants.PATH.MSG_MANAGEMENT
									}
								/>
							) : !!loggedIn && user ? (
								<Loading />
							) : (
								<LoginPage />
							)
						}
					/>
					<Route exact path={generalConstants.PATH.REGISTER} render={() => <RegisterPage />} />
					<PrivateRoute path={generalConstants.PATH.EDIT_ORGANIZATION} component={EditOrganizationPage} />
					<PrivateRoute path={generalConstants.PATH.CHANGE_PASSWORD} component={ChangePasswordPage} />

					<PrivateRoute path={generalConstants.PATH.MSG_MANAGEMENT} component={MsgManagementPage} />
					<PrivateRoute path={generalConstants.PATH.NEW_MSG} component={NewMsgPage} />
					<PrivateRoute path={generalConstants.PATH.EDIT_MSG} component={EditMsgPage} />
					<PrivateRoute path={generalConstants.PATH.MSG_CONFIRM} component={MsgConfirmPage} />

					<PrivateRoute path={generalConstants.PATH.SCHOOL_MANAGEMENT} component={SchoolManagementPage} />
					<PrivateRoute exact path={generalConstants.PATH.SCHOOL_DETAIL} component={SchoolDetailScreen} />
					<PrivateRoute path={generalConstants.PATH.EDIT_SCHOOL} component={EditSchoolPage} />

					<PrivateRoute path={`${generalConstants.PATH.SCHOOL_DETAIL}/message/add`} component={NewMsgPage} />
					<PrivateRoute path={`${generalConstants.PATH.SCHOOL_DETAIL}/message/edit`} component={EditMsgPage} />
					<PrivateRoute path={`${generalConstants.PATH.SCHOOL_DETAIL}/message/confirm`} component={MsgConfirmPage} />
					<Route path="*">
						<h1>404</h1>
					</Route>
				</Switch>
			</Router>
		</div>
	);
}

export default App;
