import React from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import { default as MuiSelect } from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";

export const Select = (props) => {
	const { name, options, value, handleChange, displayEmpty, emptyText } = props;

	const renderItem = () => {
		if (!!options && options.length > 0) {
			return options.map((option, index) => {
				return (
					<MenuItem key={`${name}_select_${index}`} value={option.key}  style={{ fontSize: 13 }}>
						{option.value}
					</MenuItem>
				);
			});
		}
	};

	const renderItems = renderItem();

	return (
		<FormControl variant="outlined" fullWidth>
			<MuiSelect  margin='dense'
				inputProps={{ style: {fontSize: 13} }}
				id={`${name}_select`}
				onChange={(event) => {
					if (handleChange) {
						handleChange({ key: event.target.name, value: event.target.value });
					}
				}}
				name={name}
				value={value}
				input={<OutlinedInput  style={{ fontSize: 13 }} labelWidth={0} />}
				displayEmpty={displayEmpty}
			>
				{!!displayEmpty && (
					<MenuItem value={""} style={{ fontSize: 13,height: "36px" }}>
						{emptyText ? emptyText : ""}
					</MenuItem>
				)}
				{renderItems}
			</MuiSelect>
		</FormControl>
	);
};

Select.propTypes = {
	name: PropTypes.string,
	options: PropTypes.array,
	value: PropTypes.string,
	handleChange: PropTypes.func,
	displayEmpty: PropTypes.bool,
	emptyText: PropTypes.string,
};

Select.default = {
	displayEmpty: false,
};
