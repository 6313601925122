import React from "react";
import styled from "styled-components";

export const AdminFooter = () => {
	const getCopyrightYear = () => {
		return new Date().getFullYear();
	};

	return (
		<Container>
			<FooterLayer>
				<RowInBlock style={{ justifyContent: "flex-end" }}>
					<FooterText>Copyright © {getCopyrightYear()} 伴你童行 Kids on Track</FooterText>
				</RowInBlock>
			</FooterLayer>
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	padding-bottom: 1px;
	height: 48px;
`;

const FooterLayer = styled.div``;

const RowInBlock = styled.div`
	height: 100%;
	display: flex;
	flex-direction: row;
`;

const FooterText = styled.div`
	margin: 11px 30px;
	font-size: 11px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 2;
	letter-spacing: normal;
	text-align: left;
	color: #666666;
`;
