import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useKey } from "_util/hook";
import CloseIcon from "@material-ui/icons/Close";
import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { TextField, YellowButton } from "components/general";
import { MOBILE_MAX_WIDTH, generalConstants } from "_constants";
import { useSelector } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import ProgressiveRejectDebouncer from "_util/debouncers/ProgressiveRejectDebouncer";

export const ForgetPasswordForm = (props) => {
	const { open, handleForgetPassword, onClose } = props;
	const [debouncer] = useState(() => new ProgressiveRejectDebouncer(1 * 1000));
	const recaptchaRef = useRef(null);
	const [formData, setFormData] = useState({ email: "" });

	const [isSubmitting, setSubmitting] = useState(false);

	const isFetching = useSelector(({ auth }) => auth.isFetching);
	const forgetPasswordSuccess = useSelector(({ auth }) => auth.forgetPasswordSuccess);
	const forgetPasswordFailure = useSelector(({ auth }) => auth.forgetPasswordFailure);

	const enterPress = useKey("enter");

	useEffect(() => {
		if (enterPress && open) {
			handleOnClick();
		}
	}, [enterPress]);

	useEffect(() => {
		if (recaptchaRef.current && !isFetching && !forgetPasswordSuccess) {
			setFormData({ ...formData, recaptchaValue: undefined });
		}
		if (!isFetching && forgetPasswordSuccess) {
			setSubmitting(false);
		}
	}, [isFetching, forgetPasswordSuccess]);

	useEffect(() => {
		if (forgetPasswordFailure) {
			setSubmitting(false);
		}
	}, [forgetPasswordFailure]);

	useEffect(() => {
		if (open) {
			setFormData({ email: "" });
		}
	}, [open]);

	const handleChange = ({ key, value }) => {
		setFormData({ ...formData, [key]: value });
	};
	const handleOnClick = async () => {
		if (!formData.email || formData.email.trim().length === 0) {
			toast.error("請填寫電郵");
			return;
		}
		if (!debouncer.token()) {
			return;
		}
		try {
			const token = await recaptchaRef.current.executeAsync();
			recaptchaRef.current.reset();
			handleForgetPassword({ ...formData, recaptchaValue: token });
		} catch {
			setSubmitting(false);
		}
	};

	return (
		<Dialog open={open} onClose={onClose} BackdropProps={{ style: { backgroundColor: "#000000cc" } }}>
			{(!!isFetching || !!isSubmitting) && (
				<LoadingContainer>
					<LoadingBox>
						<CircularProgress />
					</LoadingBox>
				</LoadingContainer>
			)}
			<DialogContent style={{ paddingBottom: 20, paddingRight: 0, paddingTop: 0 }}>
				<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
					<ForgetPasswordTitle style={{ paddingTop: 20 }}>忘記密碼</ForgetPasswordTitle>
					<CloseButton onClick={onClose}>
						<CloseIcon style={{ marginRight: 15, marginTop: 15 }} />
					</CloseButton>
				</div>
				<SubTitle>我們將通過電子郵件向您發送重置密碼的鏈接。</SubTitle>
			</DialogContent>
			<BlueBottom>
				<DialogContent style={{ paddingTop: 15 }}>
					<WhiteTitle>電郵地址</WhiteTitle>
					<TextField
						placeholder="電郵地址"
						style={{ backgroundColor: "white", marginTop: 5, marginBottom: 20 }}
						value={formData.email}
						name="email"
						handleChange={handleChange}
						disabled={isSubmitting}
					/>
					<WhiteText>如需協助，請與我們聯絡：</WhiteText>
					<WhiteText>
						電郵：{" "}
						<FooterCSEMail target="_black" href={generalConstants.PATH.CONTACT_US}>
							cs@squarebox.com.hk
						</FooterCSEMail>
					</WhiteText>
				</DialogContent>
				<DialogActions style={{ display: "block", padding: 20 }}>
					<ReCaptchaWithButton>
						<ReCaptchaContainer>
							<ReCAPTCHA
								badge={"inline"}
								ref={recaptchaRef}
								sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
								size={"invisible"}
								hl="zh-HK"
								onChange={() => {
									setSubmitting(true);
								}}
							/>
						</ReCaptchaContainer>
						<ButtonContainer>
							<YellowButton handleOnClick={handleOnClick} label={"提交"} active={!isFetching} disabled={isFetching}></YellowButton>
						</ButtonContainer>
					</ReCaptchaWithButton>
				</DialogActions>
			</BlueBottom>
		</Dialog>
	);
};

ForgetPasswordForm.propTypes = {
	handleForgetPassword: PropTypes.func,
	onClose: PropTypes.func,
	open: PropTypes.bool,
};

const ForgetPasswordTitle = styled.div`
	color: #112a84;
	font-size: 36px;
	font-weight: 900;
	line-height: 1.5;
`;

const SubTitle = styled.div`
	color: #333333;
	font-size: 16px;
	line-height: 20px;
`;

const BlueBottom = styled.div`
	background-color: #112a84;
`;

const WhiteTitle = styled.div`
	color: white;
	font-size: 16px;
	line-height: 24px;
`;

const WhiteText = styled.div`
	color: white;
	font-size: 13px;
	line-height: 17px;
`;

const FooterCSEMail = styled.a`
	color: white;
	font-size: 13px;
	line-height: 17px;
`;

const ButtonContainer = styled.div`
	text-align: right;
	> button {
		width: 135px;
		font-weight: bold;
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			width: 100%;
		}
	}
`;

const ReCaptchaWithButton = styled.div`
	display: flex;
	flex-direction: column;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		flex-direction: column;
		justify-content: stretch;
	}
`;

const ReCaptchaContainer = styled.div`
	padding-left: 5px;
	padding-bottom: 15px;
`;

const CloseButton = styled.div`
	width: 50;
	height: 50;
	display: "flex";
	justify-content: "end";
	aligncontent: "center";
	cursor: pointer;
`;

const LoadingContainer = styled.div`
	background-color: white;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	justify-content: center;
	align-items: center;
	z-index: 200;
	opacity: 0.7;
`;

const LoadingBox = styled.div`
	display: flex;
	position: absolute;
	top: 45%;
	left: 43%;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		left: 44%;
	}
`;
