export const authConstants = {
	type: {
		LOGIN_REQUEST: "LOGIN_REQUEST",
		LOGIN_SUCCESS: "LOGIN_SUCCESS",
		LOGIN_FAIL: "LOGIN_FAIL",
		LOGOUT_REQUEST: "LOGOUT_REQUEST",
		LOGOUT_FAIL: "LOGOUT_FAIL",
		LOGOUT: "LOGOUT",
		REGISTER_REQUEST: "REGISTER_REQUEST",
		REGISTER_SUCCESS: "REGISTER_SUCCESS",
		REGISTER_FAIL: "REGISTER_FAIL",
		REGISTER_RESET: "REGISTER_RESET",
		UNAUTHORIZED_ERROR: "UNAUTHORIZED_ERROR",
		CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
		CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
		CHANGE_PASSWORD_FAIL: "CHANGE_PASSWORD_FAIL",
		CHANGE_PASSWORD_INIT: "CHANGE_PASSWORD_INIT",
		RESET_PASSWORD_INIT: "RESET_PASSWORD_INIT",
		AUTH_INIT: "AUTH_INIT",
		RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
		RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
		RESET_PASSWORD_FAIL: "RESET_PASSWORD_FAIL",
		FETECH_ORGANIZATION_REQUEST: "FETECH_ORGANIZATION_REQUEST",
		FETECH_ORGANIZATION_FAIL: "FETECH_ORGANIZATION_FAIL",
		FETECH_ORGANIZATION_SUCCESS: "FETECH_ORGANIZATION_SUCCESS",
		EDIT_ORGANIZATION_REQUEST: "EDIT_ORGANIZATION_REQUEST",
		EDIT_ORGANIZATION_SUCCESS: "EDIT_ORGANIZATION_SUCCESS",
		EDIT_ORGANIZATION_FAIL: "EDIT_ORGANIZATION_FAIL",
		FORGET_PASSWORD_REQUEST: "FORGET_PASSWORD_REQUEST",
		FORGET_PASSWORD_SUCCESS: "FORGET_PASSWORD_SUCCESS",
		FORGET_PASSWORD_FAIL: "FORGET_PASSWORD_FAIL",
	},
	status: {
		ACTIVE: "ACTIVE",
		SUSPEND: "SUSPEND",
		CLOSED: "CLOSED",
		BLOCKED: "BLOCKED",
	},
	approvalStatus: {
		PENDING: "PENDING",
		REJECTED: "REJECTED",
		APPROVED: "APPROVED",
	},
	statusLabel: {
		ACTIVE: "正常",
		SUSPEND: "暫停",
		CLOSED: "關閉",
		BLOCKED: "已封鎖",
	},
	approvalStatusLabel: {
		PENDING: "待批核",
		REJECTED: "不獲批核",
		APPROVED: "通過批核",
	},
	errorCode: {
		EXPIRED_OOB_CODE: "郵件鏈接已過期，請重新要求發送。",
	},
};
