import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import { Typography } from "@material-ui/core";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import { default as MuiCheckbox } from "@material-ui/core/Checkbox";
//import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
//import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CircleUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';


export const Checkbox = (props) => {
	const { name, label, value, checked, handleChange } = props;
	const ColorCheckbox = withStyles({
		root: {
			color: "#333399",
			"&$checked": {
				color: "#333399",
			},
		},
		checked: {},
	})((props) => <MuiCheckbox color="default" {...props} />);

	return (
		<FormControlLabel
			control={
				<ColorCheckbox
					id={`${name}_${value}_checkbox`}
					checked={checked}
					onChange={(event) => {
						if (handleChange) {
							handleChange({ key: event.target.name, value: value });
						}
					}}
					icon={<CircleUncheckedIcon />}
					checkedIcon={<CheckCircleIcon />}
					name={name}
				/>
			}
			label={<Typography style={{fontSize:13}}>{label}</Typography>}
		/>
	);
};

Checkbox.propTypes = {
	name: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.string,
	checked: PropTypes.bool,
	handleChange: PropTypes.func,
};
