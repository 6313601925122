import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import CloseIcon from "@material-ui/icons/Close";
import { Dialog, DialogContent } from "@material-ui/core";
import { useSelector } from "react-redux";
import { generalConstants } from "_constants";
import titleImg from "resources/img/title.png";

export const RegisterCompleteMsg = (props) => {
	const { open, onClose } = props;
	const registerRefNo = useSelector(({ auth }) => auth.registerRefNo);

	return (
		<Dialog open={open} onClose={onClose} BackdropProps={{ style: { backgroundColor: "#000000cc" } }}>
			<DialogContent style={{ paddingBottom: 20, paddingRight: 0, paddingTop: 0 }}>
				<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
					<WelcomeText>
						歡迎加入
						<TitleLogo src={titleImg} />
					</WelcomeText>
					<CloseButton onClick={onClose}>
						<CloseIcon style={{ marginRight: 15, marginTop: 15 }} />
					</CloseButton>
				</div>
				<ContentText>我們將批核您的申請，並會以電子郵件通知批核結果。</ContentText>
				<ReferenceText>
					參考編號:<ReferenceNumText>{registerRefNo}</ReferenceNumText>
				</ReferenceText>
				<div style={{ paddingTop: 20 }}></div>
				<FooterText>如需協助，請與我們聯絡：</FooterText>
				<FooterText>
					電郵：{" "}
					<FooterCSEMail target="_black" href={generalConstants.PATH.CONTACT_US}>
						cs@squarebox.com.hk
					</FooterCSEMail>
				</FooterText>
			</DialogContent>
		</Dialog>
	);
};

RegisterCompleteMsg.propTypes = {
	onClose: PropTypes.func,
	open: PropTypes.bool,
};

const WelcomeText = styled.div`
	display: flex;
	flex-direction: row;
	color: #112a84;
	line-height: 1.5;
	font-size: 36px;
	font-weight: 900;
`;

const ReferenceText = styled.div`
	display: flex;
	flex-direction: row;
	color: #000000;
	line-height: 1.43;
	font-size: 14px;
	font-weight: bold;
`;

const ReferenceNumText = styled.div`
	color: #199cdc;
	margin-left: 4px;
`;

const ContentText = styled.div`
	color: #333;
	line-height: 1.88;
	font-size: 16px;
	font-weight: 500;
	padding-bottom: 4px;
	border-bottom: 1px solid #ededed;
	margin-bottom: 10px;
`;

const FooterText = styled.div`
	color: #333;
	line-height: 1.31;
	font-size: 13px;
`;

const FooterCSEMail = styled.a`
	color: #333;
	line-height: 1.31;
	font-size: 13px;
`;

const TitleLogo = styled.img`
	padding-top: 14px;
	width: 170px;
	height: 32px;
`;

const CloseButton = styled.div`
	width: 50;
	height: 50;
	display: "flex";
	justify-content: "end";
	aligncontent: "center";
	cursor: pointer;
`;
