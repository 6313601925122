export const statusConstants = {
	status: {
		MSG_PENDING: { key: "MSG_PENDING", label: "待發送", type: "PENDING" },
		MSG_SUCCESS: { key: "MSG_SUCCESS", label: "發送成功", type: "SUCCESS" },
		MSG_ERROR: { key: "MSG_ERROR", label: "發送錯誤", type: "FAILURE" },
		MSG_DELETED: { key: "MSG_DELETED", label: "已移除", type: "FAILURE" },
		MSG_PROCESSING: { key: "MSG_PROCESSING", label: "處理中", type: "PENDING" },
		ADMIN_STATUS_ACTIVE: {
			key: "ADMIN_STATUS_ACTIVE",
			label: "正常",
			type: "SUCCESS",
		},
		ADMIN_STATUS_SUSPEND: {
			key: "ADMIN_STATUS_SUSPEND",
			label: "暫停",
			type: "PENDING",
		},
		ADMIN_STATUS_CLOSED: {
			key: "ADMIN_STATUS_CLOSED",
			label: "關閉",
			type: "FAILURE",
		},
		ADMIN_STATUS_LOCKED: {
			key: "ADMIN_STATUS_LOCKED",
			label: "鎖定",
			type: "FAILURE",
		},
		ADMIN_STATUS_UNACTIVATE: {
			key: "ADMIN_STATUS_UNACTIVATE",
			label: "未激活",
			type: "PENDING",
		},
		REPORT_PENDING: { key: "REPORT_PENDING", label: "待處理", type: "PENDING" },
		REPORT_COMPLETED: {
			key: "REPORT_COMPLETED",
			label: "已處理",
			type: "SUCCESS",
		},
		REPORT_INPROGRESS: {
			key: "REPORT_INPROGRESS",
			label: "處理中",
			type: "SUCCESS",
		},
		REPORT_BLOCKED: { key: "REPORT_BLOCKED", label: "已封鎖", type: "BLOCK" },
		REPORT_DELETED: { key: "REPORT_DELETED", label: "已刪除", type: "FAILURE" },
		PARENT_ACTIVE: { key: "PARENT_ACTIVE", label: "正常", type: "SUCCESS" },
		PARENT_BLOCK: { key: "PARENT_BLOCK", label: "已封鎖", type: "FAILURE" },
		PARENT_CLOSED: { key: "PARENT_CLOSED", label: "關閉", type: "PENDING" },
		PARENT_UNACTIVATE: {
			key: "PARENT_UNACTIVATE",
			label: "未激活",
			type: "PENDING",
		},
		SOCIAL_NETWORK_VERIFIED: {
			key: "SOCIAL_NETWORK_VERIFIED",
			label: "已驗証",
			type: "SUCCESS",
		},
		SOCIAL_NETWORK_UNVERIFIED: {
			key: "SOCIAL_NETWORK_UNVERIFIED",
			label: "未驗証",
			type: "PENDING",
		},
		SOCIAL_NETWORK_NONE: {
			key: "SOCIAL_NETWORK_NONE",
			label: "未加入",
			type: "FAILURE",
		},
		COURSE_OPEN: { key: "COURSE_OPEN", label: "正常", type: "SUCCESS" },
		COURSE_CANCELLED: { key: "COURSE_CANCELLED", label: "取消", type: "PENDING" },
		COURSE_SUSPENDED: { key: "COURSE_SUSPENDED", label: "暫停", type: "PENDING" },
		COURSE_CLOSED: { key: "COURSE_CLOSED", label: "已停辦", type: "FAILURE" },
	},
	adminMsgStatus: {
		PENDING: 0,
		FINISH: 1,
		FAIL: 2,
	},
	type: {
		SUCCESS: "SUCCESS",
		PENDING: "PENDING",
		FAILURE: "FAILURE",
		BLOCK: "BLOCK",
	},
	adminStatus: [
		{ key: "ADMIN_STATUS_ACTIVE", value: "正常" },
		{ key: "ADMIN_STATUS_SUSPEND", value: "暫停" },
		{ key: "ADMIN_STATUS_CLOSED", value: "關閉" },
		{ key: "ADMIN_STATUS_LOCKED", value: "鎖定" },
		{ key: "ADMIN_STATUS_UNACTIVATE", value: "未激活" },
	],
};
