import React, { useState, useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { IconButton } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import InputFiles from "react-input-files";
import { SubmitButton } from "components/general";
import NoImg from "resources/img/noimg.png";

import "./css/Loader.css";

export const SingleImageUploader = (props) => {
	const { src, handleChange, handleClear, uploadLabel, noImageLabel } = props;

	const [hasImage, setHasImage] = useState(false);
	const [image, setImage] = useState(null);
	const [imageSrc, setImageSrc] = useState(null);

	const nocachet = useRef(Date.now());

	const loadImgSuccess = () => {
		if (!hasImage) setHasImage(true);
	};

	const loadImgFail = () => {
		if (hasImage) setHasImage(false);
	};

	const handleClearImage = () => {
		setHasImage(false);
		setImage(null);
		handleClear();
	};

	const onChangeImage = (files) => {
		setImage(files[0]);
		handleChange(files[0]);
	};

	const getImageSrc = useCallback(() => {
		const finalData = image ? URL.createObjectURL(image) : src ? src + "?t=" + nocachet.current : null;
		return finalData;
	}, [image, src]);

	useEffect(() => {
		setImageSrc(getImageSrc());
	}, [image, getImageSrc]);

	return (
		<ImageUploader>
			<ImageContainer>
				<UploadImg style={{ display: hasImage ? "" : "none" }} src={imageSrc} onLoad={loadImgSuccess} onError={loadImgFail} />
				{hasImage && (
					<IconButton
						onClick={handleClearImage}
						size="small"
						style={{
							backgroundColor: "#a7a7a7",
							padding: "1px",
							color: "white",
							position: "absolute",
							top: "-15px",
							right: "-15px",
						}}
					>
						<ClearIcon fontSize="small" />
					</IconButton>
				)}
				{!hasImage && <NoImage src={NoImg} alt={noImageLabel}></NoImage>}
			</ImageContainer>

			<InputFiles
				accept=".png, .jpg, .jpeg"
				onChange={(files) => {
					onChangeImage(files);
				}}
			>
				<SubmitButton
					color="primary"
					size="small"
					aria-label="add"
					variant="extended"
					style={{
						padding: "3px 14px",
						borderRadius: "20px",
						backgroundColor: "#333399",
					}}
					label={uploadLabel}
				></SubmitButton>
			</InputFiles>
		</ImageUploader>
	);
};

const ImageUploader = styled.div`
	text-align: center;
`;

const ImageContainer = styled.div`
	max-width: 80px;
	max-height: 80px;
	display: inline-block;
	position: relative;
`;

const UploadImg = styled.img`
	max-width: 100%;
`;

const NoImage = styled.img`
	background-color: #f2f2f2;
	width: 70px;
	height: 70px;
	line-height: 70px;
`;

SingleImageUploader.propTypes = {
	src: PropTypes.string,
	handleChange: PropTypes.func,
	handleClear: PropTypes.func,
	uploadLabel: PropTypes.string,
	noImageLabel: PropTypes.string,
};

SingleImageUploader.defaultProps = {
	src: "",
	handleChange: null,
	handleClear: null,
	uploadLabel: "上載圖片",
	noImageLabel: "沒有圖片",
};
