import { authConstants, schoolConstants } from "_constants";

const initialState = {
	isFetching: false,
	updateSchoolSuccess: false,
	school: {},
	sortingKey: "",
	sortingDirection: "",
	schoolList: [],
	numOfPage: 1,
	currentPage: 1,
	totalNumOfSchool: 0,
	imageCache: "",
};

const schoolReducer = (state = initialState, action) => {
	switch (action.type) {
		case authConstants.type.UNAUTHORIZED_ERROR: {
			return { ...state, currentPage: 1 };
		}
		case schoolConstants.type.FETCH_SCHOOL_LIST_REQUEST: {
			return {
				...state,
				isFetching: true,
			};
		}
		case schoolConstants.type.FETCH_SCHOOL_LIST_FAILURE: {
			return {
				...state,
				isFetching: false,
			};
		}
		case schoolConstants.type.FETCH_SCHOOL_LIST_SUCCESS: {
			return {
				...state,
				isFetching: false,
				numOfPage: action.totalPage,
				currentPage: action.currentPage ?? 1,
				totalNumOfSchool: action.totalNum ?? 0,
				schoolList: action.schoolList,
				sortingKey: action.sortingKey,
				sortingDirection: action.sortingDirection,
			};
		}
		case schoolConstants.type.FETCH_SCHOOL_REQUEST: {
			return {
				...state,
				isFetching: true,
				school: {},
			};
		}
		case schoolConstants.type.FETCH_SCHOOL_FAILURE: {
			return {
				...state,
				isFetching: false,
			};
		}
		case schoolConstants.type.FETCH_SCHOOL_SUCCESS: {
			return {
				...state,
				isFetching: false,
				school: action.school,
			};
		}
		case schoolConstants.type.UPDATE_SCHOOL_REQUEST: {
			return {
				...state,
				updateSchoolSuccess: false,
			};
		}
		case schoolConstants.type.UPDATE_SCHOOL_FAILURE: {
			return {
				...state,
				updateSchoolSuccess: false,
			};
		}
		case schoolConstants.type.UPDATE_SCHOOL_SUCCESS: {
			return {
				...state,
				updateSchoolSuccess: true,
			};
		}
		case schoolConstants.type.RESET_SCHOOL_SUCCESS: {
			return {
				...state,
				createSchoolSuccess: false,
				updateSchoolSuccess: false,
				imageCache: action.filePath,
			};
		}
		case schoolConstants.type.CLEAR_SCHOOL_BADGE_CACHE: {
			return {
				...state,
				imageCache: "",
			};
		}
		case schoolConstants.type.SCHOOL_FETCH_MSG_LIST_REQUEST: {
			return {
				...state,
				isFetching: true,
			};
		}
		case schoolConstants.type.SCHOOL_FETCH_MSG_LIST_FAILURE: {
			return {
				...state,
				isFetching: false,
			};
		}
		default: {
			return state;
		}
	}
};

export default schoolReducer;
