import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Loading, Status } from "components/general";
import { generalConstants, messageConstants, schoolConstants, schoolDataConstants, DESKTOP_MIN_WIDTH, MOBILE_MAX_WIDTH } from "_constants";
import { messageAction, schoolAction } from "actions";
import { useLocation } from "react-router-dom";
import { history, getMsgStatus } from "_util/_helper";
import Paper from "@material-ui/core/Paper";
import _ from "lodash";
import { ArrowDropDown, ArrowDropUp, Done } from "@material-ui/icons";
import moment from "moment";
import CheckIcon from "@material-ui/icons/Check";
import editIcon from "resources/img/school_inner_pencil.svg";
import backIcon from "resources/img/backet_arrow.svg";
import IconButton from "@material-ui/core/IconButton";

import { Desktop, desktop, Mobile } from "components/responsive";
import { AdminMenubar, AdminUserInfo, AdminSectionbar, AdminFooter } from "components/admin";
import { Pagination } from "components/general";
import {
	PageContainer,
	LeftContainer,
	RightContainer,
	Table,
	TableHeaderRow,
	TableTh,
	THButton,
	TableTd,
	PaginationContainer,
	TableContainer,
	TableRow,
} from "components/layout";

import { ReactComponent as NewMsgIcon } from "resources/img/newmsg.svg";

const FROM_SCHOOL = "school";
const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 10;
export const SchoolDetailScreen = () => {
	const location = useLocation();
	const [key, setSchoolKey] = useState(location?.state?.schoolKey);

	useEffect(() => {
		setSchoolKey(location?.state?.schoolKey);

		return () => {
			dispatch({ type: schoolConstants.type.CLEAR_SCHOOL_BADGE_CACHE });
		};
	}, [location?.state]);

	const subsidiaryList = useSelector(({ auth }) => auth.subsidiaryList);

	const { isFetching, school, schoolBadgeCache, msgList, currentPage, numOfPage, messageSortingKey, messageSortingDirection } = useSelector(
		({ school, message }) => ({
			isFetching: school.isFetching,
			school: school.school,
			schoolBadgeCache: school.imageCache,
			msgList: message.navigationObject?.school?.msgList,
			currentPage: message.navigationObject?.school?.currentPage,
			numOfPage: message.navigationObject?.school?.numOfPage,
			messageSortingKey: message.navigationObject?.school?.sortingKey,
			messageSortingDirection: message.navigationObject?.school?.sortingDirection,
		})
	);
	const loggedIn = useSelector(({ auth }) => auth.loggedIn);
	const [sortingKey, setSortingKey] = useState(messageSortingKey);
	const [sortingDirection, setSortingDirection] = useState(messageSortingDirection);

	const initSearchMsgForm = {
		msg: {
			infoType: "SCHOOL",
		},
		school: {
			key,
		},
		limit: DEFAULT_LIMIT,
		page: currentPage,
		sort: `${sortingKey}:${sortingDirection}`,
	};

	const [searchMsgForm, setSearchMsgForm] = useState(initSearchMsgForm);

	const baseURL = `${process.env.REACT_APP_SCHOOL_BADGE_LINK}`;

	useEffect(() => {
		if (key && loggedIn) {
			dispatch(
				schoolAction.getSchoolByKey(key, {
					...searchMsgForm,
					includeMessage: true,
					page: school ? (school && school._key !== key ? DEFAULT_PAGE : currentPage) : DEFAULT_PAGE,
				})
			);
		}
	}, [key, loggedIn]);

	useEffect(() => {
		setSearchMsgForm({
			...searchMsgForm,
			page: school ? (school && school._key !== key ? DEFAULT_PAGE : currentPage) : DEFAULT_PAGE,
		});
	}, [school]);

	const nocachet = useRef(Date.now());

	const [isLoading, setIsLoading] = useState(false);
	const [showSchoolInfo, setShowSchoolInfo] = useState(false);
	const [label, setLabel] = useState("");
	const [overview, setOverview] = useState();
	const [gender, setGender] = useState();
	const [religion, setReligion] = useState();
	const [linkMode, setLinkMode] = useState();

	const [level, setLevel] = useState();
	const [district, setDistrict] = useState();
	const [financeType, setFinanceType] = useState();
	const [session, setSession] = useState();
	const [schoolNet, setSchoolNet] = useState();
	const [category, setCategory] = useState();

	const dispatch = useDispatch();

	useEffect(() => {
		setIsLoading(isFetching);
	}, [isFetching]);

	useEffect(() => {
		if (school.name) {
			setLabel(school.name[0]);
		} else {
			setLabel("");
		}
		let tmpstr = "";
		try {
			setLevel(schoolConstants.level[school.level].label);
		} catch (e) {
			setLevel("");
		}
		try {
			setCategory(schoolConstants.OPTION.category[school.level].filter((a) => a.key === school.category)[0].value);
		} catch (e) {
			setCategory("");
		}
		try {
			setDistrict(schoolConstants.district[school.district].label);
		} catch (e) {
			setDistrict("");
		}
		try {
			setFinanceType(schoolConstants.OPTION.financeType[school.level].filter((a) => a.key === school.financeType)[0].value);
		} catch (e) {
			setFinanceType("");
		}
		if (school.session) {
			let tcount = 0;
			school.session.forEach((s) => {
				try {
					tmpstr = tmpstr.concat((tcount > 0 ? "+" : "") + schoolConstants.session[s].label);
					tcount++;
				} catch (e) {
					tmpstr = tmpstr.concat("");
				}
			});
			setSession(tmpstr);
		}
		setSchoolNet(school.schoolNet);
		try {
			setGender(schoolConstants.gender[school.gender].label);
		} catch (e) {
			setGender("");
		}

		try {
			setReligion(schoolConstants.religion[school.religion].label);
		} catch (e) {
			setReligion("");
		}
		if (school.linkMode) {
			setLinkMode(school.linkMode);
		} else {
			setLinkMode("");
		}
	}, [school]);

	useEffect(() => {
		let str = "";

		if (level) {
			str = str.concat(level);
		}

		if (district) {
			if (str.length > 0) str = str.concat(" / ");
			str = str.concat(district);
		}

		if (financeType) {
			if (str.length > 0) str = str.concat(" / ");
			str = str.concat(financeType);
		}

		if (schoolNet) {
			if (str.length > 0) str = str.concat(" / ");
			str = str.concat(`${schoolNet}校網`);
		}

		if (gender) {
			if (str.length > 0) str = str.concat(" / ");
			str = str.concat(gender);
		}

		setOverview(str);
	}, [level, district, financeType, schoolNet, gender]);

	const getCategory = (item) => {
		try {
			if (_.isEmpty(item.category)) return "";
			return "[" + messageConstants.Translate.category[item.category] + "] ";
		} catch (e) {
			return "";
		}
	};

	const handleSortAction = (sort) => {
		let direction = "DESC";
		if (sortingKey === sort && sortingDirection === "DESC") {
			direction = "ASC";
		}
		setSortingKey(sort);
		setSortingDirection(direction);
		setSearchMsgForm({ ...searchMsgForm, page: DEFAULT_PAGE, sort: `${sort}:${direction}` });
		dispatch(messageAction.fetchMessages({ ...searchMsgForm, page: DEFAULT_PAGE, sort: `${sort}:${direction}` }, "school"));
	};

	const renderTH = () => {
		return messageConstants.SCHOOL_MSG_TABLE_COLUMN.map((column) => {
			return (
				<StyledTableTh className={column.key} key={`${column.key}_table_header`}>
					<THButton key={`${column.key}_table_header`} type="button" onClick={() => handleSortAction(column.key)}>
						<div style={{ textAlign: column.key === "status" || column.key === "fired" ? "center" : "auto" }}>
							{column.value}
							{sortingKey === column.key ? (
								sortingDirection === "ASC" ? (
									<ArrowDropUp fontSize={"small"} style={{ verticalAlign: "middle" }} />
								) : (
									<ArrowDropDown fontSize={"small"} style={{ verticalAlign: "middle" }} />
								)
							) : null}
						</div>
					</THButton>
				</StyledTableTh>
			);
		});
	};
	const renderTableHeader = renderTH();

	const renderTD = () => {
		if (msgList && msgList?.length > 0) {
			return msgList?.map((school, index) => {
				const tempObj = {};
				messageConstants.SCHOOL_MSG_TABLE_COLUMN.forEach((column) => {
					tempObj[column.key] = school[column.key];
				});

				return (
					<TableRow
						key={`${school._id}_${index}_tr`}
						onClick={() => {
							HandleRowOnClick(school);
						}}
					>
						{Object.keys(tempObj).map((columnName, index) => {
							if (columnName === "status") {
								return (
									<StyledTableTd className={columnName} key={`${school._id}_${columnName}_${index}_td`} style={{ verticalAlign: "middle" }}>
										<Status status={getMsgStatus(school)} />
									</StyledTableTd>
								);
							} else if (columnName === "type" || columnName === "infoType" || columnName === "targetAudience") {
								return (
									<StyledTableTd className={columnName} key={`${school._id}_${columnName}_${index}_td`}>
										{messageConstants.Translate[columnName][tempObj[columnName]]}
									</StyledTableTd>
								);
							} else if (columnName === "title") {
								return (
									<StyledTableTd className={columnName} key={`${school._id}_${columnName}_${index}_td`}>
										<TitleText>
											{getCategory(school)}
											{tempObj[columnName]}
										</TitleText>
									</StyledTableTd>
								);
							} else if (columnName === "scheduleTime") {
								return (
									<StyledTableTd className={columnName} style={{ whiteSpace: "nowrap" }} key={`${school._id}_${columnName}_${index}_td`}>
										{tempObj[columnName] ? moment(tempObj[columnName]).format("YYYY年M月DD日 hh:mmA") : "-"}
									</StyledTableTd>
								);
							} else if (columnName === "fired") {
								return (
									<StyledTableTd className={columnName} key={`${school._id}_${columnName}_${index}_td`} style={{ textAlign: "center" }}>
										{tempObj[columnName] ? <Done /> : null}
									</StyledTableTd>
								);
							}
							return (
								<StyledTableTd className={columnName} key={`${school._id}_${columnName}_${index}_td`}>
									{tempObj[columnName]}
								</StyledTableTd>
							);
						})}
					</TableRow>
				);
			});
		}
	};
	const renderTableCell = renderTD();

	const HandleRowOnClick = (item) => {
		history.push({
			pathname: `${generalConstants.PATH.SCHOOL_DETAIL}/message/edit`,
			state: { from: FROM_SCHOOL, school, schoolKey: school._key, msgKey: item._key },
		});
	};

	const HandleEditButtonOnClick = () => {
		history.push({ pathname: generalConstants.PATH.EDIT_SCHOOL, state: { schoolKey: school._key } });
	};

	const handleMsgPageChange = (pageNum) => {
		setSearchMsgForm({ ...searchMsgForm, page: pageNum });
		dispatch(messageAction.fetchMessages({ ...searchMsgForm, page: pageNum }, "school"));
	};

	const getLocationPath = () => {
		const pathArray = [];

		if (subsidiaryList?.schoolList?.totalItems !== 1 && subsidiaryList?.schoolList?.items?.length !== 1) {
			pathArray.push({
				title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.SCHOOL_MANAGEMENT.key],
				isAction: true,
				target: generalConstants.PATH.SCHOOL_MANAGEMENT,
			});
		}
		pathArray.push({
			title: label,
			isAction: false,
			target: "",
		});
		return pathArray;
	};

	const handleNewMsg = () => {
		history.push(`${generalConstants.PATH.SCHOOL_DETAIL}/message/add`, {
			from: FROM_SCHOOL,
			schoolKey: school._key,
			message: {
				infoType:
					school._id === `school/${schoolDataConstants.specialSchoolId}`
						? messageConstants.DATA.infoType.EB
						: messageConstants.DATA.infoType.SCHOOL,
				schoolId: school._id || "",
				schoolName: school.name[0] || school.name[1] || "",
				schoolVipOnly: school.vipOnly || false,
			},
		});
	};

	const sectionBar = () => {
		return (
			<AdminSectionbar
				label={
					<OverviewContainer marginBottom={"0px"}>
						{school.vipOnly && (
							<VipIconView>
								<VipText>VIP</VipText>
							</VipIconView>
						)}
						{label}
					</OverviewContainer>
				}
				buttonLabel={"發送新訊息"}
				buttonIcon={<NewMsgIcon />}
				buttonType={generalConstants.BUTTON_TYPE.SUBMIT}
				handleOnClick={handleNewMsg}
				locationPathArray={getLocationPath()}
			/>
		);
	};

	return (
		<React.Fragment>
			{!!isLoading && <Loading />}
			<PageContainer>
				<LeftContainer>
					<AdminMenubar section={generalConstants.NAV_TAB.SCHOOL_MANAGEMENT.key} />
				</LeftContainer>
				<RightContainer>
					<Desktop>
						<AdminUserInfo />
					</Desktop>
					<ContentContainer>
						<Mobile>
							{sectionBar()}
							<SchoolInfoViewButton onClick={() => setShowSchoolInfo(!showSchoolInfo)}>
								<SchoolInfoViewButtonText>學校資料</SchoolInfoViewButtonText>
								<ForwardIcon alt="" src={backIcon} />
							</SchoolInfoViewButton>
						</Mobile>
						<LeftPageContainer>
							<Desktop>{sectionBar()}</Desktop>
							<Paper elevation={desktop() ? 3 : 0}>
								<ContentLeft>
									<TableContainer>
										<Table>
											<thead>
												<TableHeaderRow>{renderTableHeader}</TableHeaderRow>
											</thead>
											<tbody>{renderTableCell}</tbody>
										</Table>
									</TableContainer>
									{msgList && msgList?.length > 0 && (
										<PaginationContainer>
											<Pagination count={numOfPage} page={currentPage} handlePageChange={handleMsgPageChange} />
										</PaginationContainer>
									)}
								</ContentLeft>
							</Paper>
						</LeftPageContainer>
						<ContentRight active={showSchoolInfo} style={{ padding: 0 }}>
							<Paper elevation={desktop() ? 3 : 0}>
								<SchoolInfoContainer>
									<SchoolInfoBackBar>
										<Mobile>
											<IconButton aria-label="返回" onClick={() => setShowSchoolInfo(false)} style={{ opacity: "0.6", padding: "5px 5px" }}>
												<BackIcon alt="" src={backIcon} />
											</IconButton>
										</Mobile>
										<TextFieldLabel>- 學校資料</TextFieldLabel>
										<SchoolButtons>
											<IconButton aria-label="編輯" onClick={HandleEditButtonOnClick} style={{ float: "right", padding: "5px" }}>
												<img alt="" src={editIcon} />
											</IconButton>
										</SchoolButtons>
									</SchoolInfoBackBar>
									{school._key && (
										<img
											src={schoolBadgeCache !== "" ? schoolBadgeCache : `${baseURL}${school._key}.jpg?t=${nocachet.current}`}
											style={{ width: "80px" }}
											alt=""
											onError={(e) => (e.target.style.visibility = "hidden")}
										/>
									)}
									{school.status === schoolConstants.status.CLOSED.key && <ClosedBadge>已停辦</ClosedBadge>}
									{school.name && school.name[0] && <SchoolNameLabel>{school.name[0]}</SchoolNameLabel>}
									{school.name && school.name[1] && <SchoolNameLabel>{school.name[1]}</SchoolNameLabel>}
									<OverviewContainer>
										{school.vipOnly && (
											<VipIconView marginRight={desktop() ? "5px" : "20px"}>
												<VipText>VIP</VipText>
											</VipIconView>
										)}
										<OverviewLabel>{overview}</OverviewLabel>
									</OverviewContainer>
									{school.address && school.address[0] && (
										<SmallRowInBlock>
											<SchoolSmalllDetaiLabel style={{ flex: 3 }}>地址</SchoolSmalllDetaiLabel>
											<SchoolSmalllDetaiLabel style={{ flex: 1 }}>:</SchoolSmalllDetaiLabel>
											<SchoolSmalllDetaiLabel style={{ flex: 17 }}>{school.address[0]}</SchoolSmalllDetaiLabel>
										</SmallRowInBlock>
									)}
									{school.address && school.address[1] && (
										<SmallRowInBlock>
											<SchoolSmalllDetaiLabel style={{ flex: 3 }}></SchoolSmalllDetaiLabel>
											<SchoolSmalllDetaiLabel style={{ flex: 1 }}></SchoolSmalllDetaiLabel>
											<SchoolSmalllDetaiLabel style={{ flex: 17 }}>{school.address[1]}</SchoolSmalllDetaiLabel>
										</SmallRowInBlock>
									)}
									{school.telNo && (
										<SmallRowInBlock>
											<SchoolSmalllDetaiLabel style={{ flex: 3 }}>電話</SchoolSmalllDetaiLabel>
											<SchoolSmalllDetaiLabel style={{ flex: 1 }}>:</SchoolSmalllDetaiLabel>
											<SchoolSmalllDetaiLabel style={{ flex: 17 }}>{school.telNo}</SchoolSmalllDetaiLabel>
										</SmallRowInBlock>
									)}
									{school.faxNo && (
										<SmallRowInBlock>
											<SchoolSmalllDetaiLabel style={{ flex: 3 }}>傳真</SchoolSmalllDetaiLabel>
											<SchoolSmalllDetaiLabel style={{ flex: 1 }}>:</SchoolSmalllDetaiLabel>
											<SchoolSmalllDetaiLabel style={{ flex: 17 }}>{school.faxNo}</SchoolSmalllDetaiLabel>
										</SmallRowInBlock>
									)}
									{school.website && (
										<SmallRowInBlock>
											<SchoolSmalllDetaiLabel style={{ flex: 3 }}>網址</SchoolSmalllDetaiLabel>
											<SchoolSmalllDetaiLabel style={{ flex: 1 }}>:</SchoolSmalllDetaiLabel>
											<SchoolSmalllDetaiLabel style={{ flex: 17 }}>
												<a
													target="_black"
													href={
														school.website.includes("http://") || school.website.includes("https://")
															? school.website
															: `http://${school.website}`
													}
												>
													{school.website}
												</a>
											</SchoolSmalllDetaiLabel>
										</SmallRowInBlock>
									)}
									<div style={{ height: "16px" }}></div>
									{school.category && (
										<ThinRowInBlock>
											<DetailTitleLabel>學校類別</DetailTitleLabel>
											<DetailMiddle>:</DetailMiddle>
											<DetailLabel>{category}</DetailLabel>
										</ThinRowInBlock>
									)}
									{gender && (
										<ThinRowInBlock>
											<DetailTitleLabel>學生性別</DetailTitleLabel>
											<DetailMiddle> :</DetailMiddle>
											<DetailLabel>{gender}</DetailLabel>
										</ThinRowInBlock>
									)}

									{school.session && (
										<ThinRowInBlock>
											<DetailTitleLabel>上課時間</DetailTitleLabel>
											<DetailMiddle>:</DetailMiddle>
											<DetailLabel>{session}</DetailLabel>
										</ThinRowInBlock>
									)}

									{school.financeType && (
										<ThinRowInBlock>
											<DetailTitleLabel>財政類型</DetailTitleLabel>
											<DetailMiddle>:</DetailMiddle>
											<DetailLabel>{financeType}</DetailLabel>
										</ThinRowInBlock>
									)}

									{religion && school._key !== schoolDataConstants.specialSchoolId && (
										<ThinRowInBlock>
											<DetailTitleLabel>宗教</DetailTitleLabel>
											<DetailMiddle>:</DetailMiddle>
											<DetailLabel>{religion}</DetailLabel>
										</ThinRowInBlock>
									)}
									{school.level === "PRIMARY" && (
										<div>
											<ThinRowInBlock>
												<DetailTitleLabel>一條龍中學</DetailTitleLabel>
												<DetailMiddle>:</DetailMiddle>

												{linkMode === "THROUGH-TRAIN" ? (
													<DetailLabel>
														<CheckIcon style={{ height: "20px" }} />
													</DetailLabel>
												) : (
													<DetailLabel>-</DetailLabel>
												)}
											</ThinRowInBlock>
											<ThinRowInBlock>
												<DetailTitleLabel>直屬中學</DetailTitleLabel>
												<DetailMiddle>:</DetailMiddle>
												{linkMode === "FEEDER" ? (
													<DetailLabel>
														<CheckIcon style={{ height: "20px" }} />
													</DetailLabel>
												) : (
													<DetailLabel>-</DetailLabel>
												)}
											</ThinRowInBlock>
											<ThinRowInBlock>
												<DetailTitleLabel>聯繫中學</DetailTitleLabel>
												<DetailMiddle>:</DetailMiddle>
												{linkMode === "NOMINATED" ? (
													<DetailLabel>
														<CheckIcon style={{ height: "20px" }} />
													</DetailLabel>
												) : (
													<DetailLabel>-</DetailLabel>
												)}
											</ThinRowInBlock>
										</div>
									)}
									{school.level !== "PRIMARY" && school.level !== "SECONDARY" && school._key !== schoolDataConstants.specialSchoolId && (
										<ThinRowInBlock>
											<DetailTitleLabel>幼稚園教育計劃</DetailTitleLabel>
											<DetailMiddle>:</DetailMiddle>
											{school.kes && school.kes === "Y" ? (
												<DetailLabel>
													<CheckIcon style={{ height: "20px" }} />
												</DetailLabel>
											) : (
												<DetailLabel>-</DetailLabel>
											)}
										</ThinRowInBlock>
									)}
								</SchoolInfoContainer>
							</Paper>
						</ContentRight>
					</ContentContainer>
					<AdminFooter />
				</RightContainer>
			</PageContainer>
		</React.Fragment>
	);
};

const ContentRight = styled.div`
	flex: 6;
	padding: 0;
	margin-top: 20px;
	@media (min-width: ${DESKTOP_MIN_WIDTH}) {
		margin-left: 20px;
	}
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		position: fixed;
		transition: all 0.3s;
		margin-top: 0;
		left: ${(props) => (props.active ? "0" : "100%")};
		font-size: 1.8em;
		z-index: 12;
		top: 0;
		background-color: #fff;
		width: 100%;
		height: 100%;

		overflow-y: scroll;
	}
`;

const LeftPageContainer = styled.div`
	flex: 16;
`;

const ContentLeft = styled.div`
	flex: 18;
	height: 620px;
	border-right: 1px solid #dddddd;
`;

const ForwardIcon = styled.img`
	transform: rotate(270deg);
	width: 13px;
	height: 8px;
	align-self: center;
	flex: 0 0 8px;
`;

const BackIcon = styled.img`
	transform: rotate(90deg);
	height: 10px;
`;

const ContentContainer = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.marginBottom || "10px"};
	flex-direction: row;
	padding-left: 20px;
	padding-right: 20px;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		flex-direction: column;
		padding-left: 0px;
		padding-right: 0px;
	}
`;

const TextFieldLabel = styled.div`
	color: #333399;
	font-size: 14px;
	line-height: 1.5;
	font-weight: bold;
	margin-bottom: 0px;
	flex: 1;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		font-size: 20px;
		text-align: center;
	}
`;

const SmallRowInBlock = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 4px;
	margin-top: 4px;
`;

const ClosedBadge = styled.span`
	padding: 2px 6px 3px;
	border-radius: 3px;
	background-color: #fff0f2;
	margin: 9px 6px 9px 0;
	font-size: 10px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #ff0000;
`;

const SchoolInfoViewButton = styled.div`
	display: flex;
	margin: 0 15px 15px 15px;
	padding: 16px 10px 16px 12px;
	border-radius: 3px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
	background-color: #fff;
	font-size: 14px;
	font-weight: bold;
	line-height: 1.43;
	color: #333399;
`;

const SchoolInfoViewButtonText = styled.div`
	flex: 1;
`;

const ThinRowInBlock = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 4px;
	margin-top: 4px;
	border-bottom: 1px solid #ccc;
`;

const SchoolInfoContainer = styled.div`
	flex: 1;
	min-height: 640px;
	margin-left: 20px;
	margin-right: 20px;
	padding-top: 20px;
	padding-bottom: 20px;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		height: 100%;
	}
`;

const SchoolInfoBackBar = styled.div`
	display: flex;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		position: sticky;
		top: 0;
		width: 100%;
		margin-bottom: 10px;
	}
`;

const SchoolButtons = styled.div`
	text-align: right;
	margin-right: 10px;
`;

const TitleText = styled.span`
	color: #0085b5;
	text-decoration: underline;
`;

const OverviewContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: ${(props) => props.marginBottom || "14px"};
`;

const OverviewLabel = styled.div`
	color: #666666;
	font-size: 12px;
`;

const VipIconView = styled.div`
	display: flex;
	width: 32px;
	flex-direction: row;
	margin-right: ${(props) => props.marginRight || "20px"};
`;

const VipText = styled.div`
	font-size: 12;
	font-weight: bold;
	color: #333333;
	background-color: #c8ae63;
	padding-left: 5px;
	padding-right: 5px;
`;

const SchoolNameLabel = styled.div`
	color: #000000;
	font-size: 14px;
	line-height: 1;
	font-weight: bold;
	margin-bottom: 4px;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		font-size: 18px;
	}
`;

const SchoolSmalllDetaiLabel = styled.div`
	color: #666666;
	font-size: 11px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.55;
	letter-spacing: normal;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		font-size: 16px;
	}
`;

const DetailTitleLabel = styled.div`
	flex: 12;
	color: #000000;
	vertical-align: middle;
	font-size: 11px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.55;
	letter-spacing: normal;
	margin: 4px 4px 5px 0;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		font-size: 14px;
	}
`;

const DetailMiddle = styled.div`
	flex: 1;
	text-align: center;
	vertical-align: middle;
	margin: 4px 5px 5px 3px;
	font-size: 11px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.55;
	letter-spacing: normal;
	color: #000000;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		font-size: 14px;
	}
`;

const DetailLabel = styled.div`
	flex: 16;
	color: #333333;
	padding-left: 5px;
	vertical-align: middle;
	margin: 4px 5px 5px 5px;
	font-size: 11px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.55;
	letter-spacing: normal;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		font-size: 14px;
	}
`;

const StyledTableTh = styled(TableTh)`
	&.status {
		width: 70px;
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			left: 0;
			z-index: 2;
			position: sticky;
			width: 70px;
		}
	}
	&.targetAudience {
		width: 85px;
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			width: 85px;
		}
	}
	&.title {
		width: auto;
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			width: calc(50vw);
		}
	}
	&.postBy {
		width: 20%;
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			width: 170px;
		}
	}
	&.fired {
		width: 8%;
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			width: calc(8vw);
		}
	}
	&.scheduleTime {
		width: 170px;
	}
`;

const StyledTableTd = styled(TableTd)`
	background-color: #fff;
	&.status {
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			left: 0;
			z-index: 2;
			position: sticky;
			width: 70px;
		}
	}
	&.title {
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			width: calc(30vw);
		}
	}
`;
