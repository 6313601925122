export const generalConstants = {
	BUTTON_TYPE: {
		SUBMIT: "SUBMIT",
		WARNING: "WARNING",
		IMPORT: "IMPORT",
	},
	NAV_TAB: {
		MSG_MANAGEMENT: { key: "MSG_MANAGEMENT", show: true },
		NEW_MSG: { key: "NEW_MSG", show: false },
		EDIT_MSG: { key: "EDIT_MSG", show: false },
		READ_MSG: { key: "READ_MSG", show: false },
		MSG_CONFIRM: { key: "MSG_CONFIRM", show: false },

		SCHOOL_MANAGEMENT: { key: "SCHOOL_MANAGEMENT", show: true },
		SCHOOL_DETAIL: { key: "SCHOOL_DETAIL", show: false },
		EDIT_SCHOOL: { key: "EDIT_SCHOOL", show: false },
		CREATE_SCHOOL: { key: "CREATE_SCHOOL", show: false },

		CHANGE_PASSWORD: { key: "CHANGE_PASSWORD", show: false },
		ACCOUNT_MANAGEMENT: { key: "ACCOUNT_MANAGEMENT", show: false },
	},
	TAB_NAME: {
		SCHOOL_MANAGEMENT: "學校管理",
		SCHOOL_DETAIL: "學校資訊",
		EDIT_SCHOOL: "編輯學校",
		CREATE_SCHOOL: "新增學校",

		MSG_MANAGEMENT: "訊息管理",
		NEW_MSG: "發送訊息",
		EDIT_MSG: "編輯訊息",
		READ_MSG: "導入訊息",
		MSG_CONFIRM: "確認訊息",

		ABOUT_US: "關於亞網",
		CUSTOMER_SERVICE: "客戶服務",
		FAQ: "常見問題",
		TERMS_CONDITION_PRIVACY: "條款及細則和私隱政策",
		CONTACT_US: "聯繫我們",
		ALL_DIFF: "全部網頁更新",
		DIFF_DETAIL: "網頁更新詳情",
		CHANGE_PASSWORD: "更改密碼",
		ACCOUNT_MANAGEMENT: "管理帳戶資料",
		DATA_SCRAP: "數據抓取",
		DATA_SCRAP_DETAIL: "抓取變化詳情",
	},
	FOOTER_TAB: {
		ABOUT_US: { key: "ABOUT_US", show: true },
		CUSTOMER_SERVICE: { key: "CUSTOMER_SERVICE", show: true },
		FAQ: { key: "FAQ", show: true },
		TERMS_CONDITION_PRIVACY: { key: "TERMS_CONDITION_PRIVACY", show: true },
		CONTACT_US: { key: "CONTACT_US", show: true },
	},
	PATH: {
		LOGIN: "/",
		REGISTER: "/register",
		AUTH: "/auth",
		FORGET_PASSWORD: "/forgetPassword",
		CHANGE_PASSWORD: "/changePassword",
		EDIT_ORGANIZATION: "/editOrganization",

		SCHOOL_MANAGEMENT: "/schoolManagement",
		SCHOOL_DETAIL: "/school",
		EDIT_SCHOOL: "/school/edit",

		MSG_MANAGEMENT: "/msgManagement",
		NEW_MSG: "/newmsg",
		EDIT_MSG: "/message",
		READ_MSG: "/readmsg",
		MSG_CONFIRM: "/msgconfirm",

		ABOUT_US: "/about_us",
		CUSTOMER_SERVICE: "/cs",
		FAQ: "https://kidsontrack.hk/doc/Privacy_Policy.html",
		TERMS_CONDITION_PRIVACY: "https://kidsontrack.hk/doc/Terms_and_Conditions.html",
		CONTACT_US: "mailto:cs@squarebox.com.hk",
	},
	EMAIL_LANDING_PAGE_TITLE_RESET: "伴你童行 - 重設密碼",
	EMAIL_LANDING_PAGE_TITLE: "伴你童行 - 設定密碼",
};
