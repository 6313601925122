import { authConstants } from "_constants";
import _ from "lodash";

const initialState = {
	isLoginFetching: false,
	loginFailure: false,
	loggedIn: false,
	signInEmail: "",
	registerRefNo: "",
	changePasswordRequesting: false,
	changePasswordSuccess: false,
	changePasswordFailure: false,
	resetPasswordRequesting: false,
	resetPasswordSuccess: false,
	resetPasswordError: "",
	isFetching: false,
	updateOrganizationSuccess: false,
	forgetPasswordSuccess: false,
	forgetPasswordFailure: false,
	user: {},
	subsidiaryList: {},
	isLogoutFetching: false,
};

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case authConstants.type.LOGIN_REQUEST: {
			return { ...state, isLoginFetching: true, loginFailure: false };
		}
		case authConstants.type.LOGIN_SUCCESS: {
			return {
				...state,
				loggedIn: true,
				user: action.user,
				signInEmail: action.data.rememberLogin ? action.data.username : "",
				isLoginFetching: false,
				loginFailure: false,
			};
		}
		case authConstants.type.LOGIN_FAIL: {
			return {
				...state,
				isLoginFetching: false,
				loginFailure: true,
			};
		}
		case authConstants.type.FETECH_ORGANIZATION_REQUEST: {
			return {
				...state,
				isFetching: true,
			};
		}
		case authConstants.type.FETECH_ORGANIZATION_FAIL: {
			return {
				...state,
				isFetching: false,
			};
		}
		case authConstants.type.FETECH_ORGANIZATION_SUCCESS: {
			if (typeof action.data.organization != "undefined") {
				return {
					...state,
					user: action.data.organization,
					subsidiaryList: _.omit(action.data, "organization"),
					isFetching: false,
					updateOrganizationSuccess: false,
				};
			} else {
				return {
					...state,
					user: action.data,
					isFetching: false,
					updateOrganizationSuccess: false,
				};
			}
		}
		case authConstants.type.REGISTER_REQUEST: {
			return { ...state, isFetching: true, registerRefNo: "" };
		}
		case authConstants.type.REGISTER_SUCCESS: {
			return { ...state, isFetching: false, registerRefNo: action.registerRefNo };
		}
		case authConstants.type.REGISTER_FAIL: {
			return { ...state, isFetching: false, registerRefNo: "" };
		}
		case authConstants.type.REGISTER_RESET: {
			return { ...state, registerRefNo: "" };
		}
		case authConstants.type.UNAUTHORIZED_ERROR: {
			return { user: {}, loggedIn: false, signInEmail: state.signInEmail };
		}
		case authConstants.type.LOGOUT_REQUEST: {
			return { ...state, isLogoutFetching: true };
		}
		case authConstants.type.LOGOUT_FAIL: {
			return { ...state, isLogoutFetching: false };
		}
		case authConstants.type.LOGOUT: {
			return { ...state, user: {}, subsidiaryList: {}, loggedIn: false, isLogoutFetching: false };
		}
		case authConstants.type.CHANGE_PASSWORD_INIT: {
			return { ...state, changePasswordRequesting: false, changePasswordSuccess: false, changePasswordFailure: false };
		}
		case authConstants.type.CHANGE_PASSWORD_REQUEST: {
			return { ...state, changePasswordRequesting: true, changePasswordSuccess: false, changePasswordFailure: false };
		}
		case authConstants.type.CHANGE_PASSWORD_SUCCESS: {
			return { ...state, changePasswordRequesting: false, changePasswordSuccess: true, changePasswordFailure: false };
		}
		case authConstants.type.CHANGE_PASSWORD_FAIL: {
			return { ...state, changePasswordRequesting: false, changePasswordSuccess: false, changePasswordFailure: true };
		}
		case authConstants.type.AUTH_INIT: {
			return { ...state, resetPasswordRequesting: false, resetPasswordSuccess: false, resetPasswordError: "" };
		}
		case authConstants.type.RESET_PASSWORD_REQUEST: {
			return { ...state, resetPasswordRequesting: true, resetPasswordError: "" };
		}
		case authConstants.type.RESET_PASSWORD_SUCCESS: {
			return { ...state, resetPasswordRequesting: false, resetPasswordSuccess: true };
		}
		case authConstants.type.RESET_PASSWORD_FAIL: {
			return { ...state, resetPasswordRequesting: false, resetPasswordSuccess: false, resetPasswordError: action.error };
		}
		case authConstants.type.EDIT_ORGANIZATION_REQUEST: {
			return { ...state, isFetching: true };
		}
		case authConstants.type.EDIT_ORGANIZATION_SUCCESS: {
			return { ...state, isFetching: false, updateOrganizationSuccess: true, user: action.data };
		}
		case authConstants.type.EDIT_ORGANIZATION_FAIL: {
			return { ...state, isFetching: false, updateOrganizationSuccess: false };
		}
		case authConstants.type.FORGET_PASSWORD_REQUEST: {
			return { ...state, isFetching: true, forgetPasswordFailure: false, forgetPasswordSuccess: false };
		}
		case authConstants.type.FORGET_PASSWORD_SUCCESS: {
			return { ...state, isFetching: false, forgetPasswordFailure: false, forgetPasswordSuccess: true };
		}
		case authConstants.type.FORGET_PASSWORD_FAIL: {
			return { ...state, isFetching: false, forgetPasswordFailure: true, forgetPasswordSuccess: false };
		}
		default: {
			return state;
		}
	}
};

export default authReducer;
