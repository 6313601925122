import { authConstants, messageConstants, schoolConstants } from "_constants";

const initialState = {
	isFetching: false,
	submitMessageSuccess: false,
	updateMessageSuccess: false,
	deleteMessageSuccess: false,
	message: {},
	navigationObject: {
		root: {
			searchWord: "",
			sortingKey: "lastUpdateTime",
			sortingDirection: "DESC",
			msgList: [],
			numOfPage: 1,
			currentPage: 1,
			totalNumOfMsg: 0,
		},
		school: {
			sortingKey: "lastUpdateTime",
			sortingDirection: "DESC",
			msgList: [],
			numOfPage: 1,
			currentPage: 1,
		},
	},
};

const messageReducer = (state = initialState, action) => {
	switch (action.type) {
		case authConstants.type.UNAUTHORIZED_ERROR: {
			let navigationObject = initialState.navigationObject;
			if (typeof state.navigationObject?.root != "undefined") {
				navigationObject = {
					...state.navigationObject,
					root: {
						...state.navigationObject.root,
						currentPage: 1,
					},
				};
			}
			if (typeof state.navigationObject?.school != "undefined") {
				navigationObject = {
					...state.navigationObject,
					school: {
						...state.navigationObject.school,
						currentPage: 1,
					},
				};
			}
			return {
				...state,
				navigationObject: navigationObject,
			};
		}
		case messageConstants.type.SUBMIT_MSG_REQUEST: {
			return {
				...state,
				submitMessageSuccess: false,
			};
		}
		case messageConstants.type.SUBMIT_MSG_FAILURE: {
			return {
				...state,
				submitMessageSuccess: false,
			};
		}
		case messageConstants.type.SUBMIT_MSG_SUCCESS: {
			return {
				...state,
				submitMessageSuccess: true,
			};
		}
		case messageConstants.type.RESET_MSG_SUCCESS: {
			return {
				...state,
				submitMessageSuccess: false,
				updateMessageSuccess: false,
				deleteMessageSuccess: false,
			};
		}
		case messageConstants.type.FETCH_MSG_LIST_SUCCESS: {
			if (action.navigationKey) {
				let navigationObject = {
					msgList: action.msgList,
					numOfPage: action.totalPage,
					currentPage: action.currentPage,
				};
				if (action.totalNum) {
					navigationObject = { ...navigationObject, totalNumOfMsg: action.totalNum };
				}
				if (action.searchWord) {
					navigationObject = { ...navigationObject, searchWord: action.searchWord };
				}
				if (action.sortingKey) {
					navigationObject = { ...navigationObject, sortingKey: action.sortingKey };
				}
				if (action.sortingDirection) {
					navigationObject = { ...navigationObject, sortingDirection: action.sortingDirection };
				}
				if (action.level) {
					navigationObject = { ...navigationObject, level: action.level };
				}
				if (action.dateRange) {
					navigationObject = { ...navigationObject, dateRange: action.dateRange };
				}
				return {
					...state,
					navigationObject: {
						...state.navigationObject,
						[`${action.navigationKey}`]: {
							...navigationObject,
						},
					},
				};
			} else {
				return state;
			}
		}
		case schoolConstants.type.FETCH_SCHOOL_SUCCESS: {
			if (action.navigationKey) {
				let navigationObject = {
					msgList: action.msgList,
					numOfPage: action.totalPage,
					currentPage: action.currentPage,
				};
				if (action.sortingKey) {
					navigationObject = { ...navigationObject, sortingKey: action.sortingKey };
				}
				if (action.sortingDirection) {
					navigationObject = { ...navigationObject, sortingDirection: action.sortingDirection };
				}
				return {
					...state,
					isFetching: false,

					navigationObject: {
						...state.navigationObject,
						[`${action.navigationKey}`]: {
							...navigationObject,
						},
					},
				};
			} else {
				return state;
			}
		}
		case messageConstants.type.FETCH_MSG_REQUEST: {
			return {
				...state,
				isFetching: true,
				message: {},
			};
		}
		case messageConstants.type.FETCH_MSG_FAILURE: {
			return {
				...state,
				isFetching: false,
			};
		}
		case messageConstants.type.FETCH_MSG_SUCCESS: {
			return {
				...state,
				isFetching: false,
				message: action.message,
			};
		}
		case messageConstants.type.UPDATE_MSG_REQUEST: {
			return {
				...state,
				updateMessageSuccess: false,
			};
		}
		case messageConstants.type.UPDATE_MSG_FAILURE: {
			return {
				...state,
				updateMessageSuccess: false,
			};
		}
		case messageConstants.type.UPDATE_MSG_SUCCESS: {
			return {
				...state,
				updateMessageSuccess: true,
			};
		}
		case messageConstants.type.DELETE_MSG_REQUEST: {
			return {
				...state,
				deleteMessageSuccess: false,
			};
		}
		case messageConstants.type.DELETE_MSG_FAILURE: {
			return {
				...state,
				deleteMessageSuccess: false,
			};
		}
		case messageConstants.type.DELETE_MSG_SUCCESS: {
			return {
				...state,
				deleteMessageSuccess: true,
			};
		}
		default: {
			return state;
		}
	}
};

export default messageReducer;
