import React, { useState, useEffect, useRef } from "react";
import { Prompt } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { useKey } from "_util/hook";
import styled from "styled-components";
import { authConstants, generalConstants, MOBILE_MAX_WIDTH } from "_constants";
import { authAction } from "actions";
import { history, validatePassword } from "_util/_helper";
import { useQuery } from "_util/hook";
import { authService } from "services";
import { Loading, SubmitButton, CancelButton, TextField, BlockMobileOverlay, EmailExpiredDialog } from "components/general";
import { toast } from "react-toastify";
import { AdminSectionbar, AdminFooter } from "components/admin";
import { PageContainer, RightContainer, ContentContainer } from "components/layout";
import { getAuth, verifyPasswordResetCode, applyActionCode } from "firebase/auth";
import ReCAPTCHA from "react-google-recaptcha";

import ProgressiveRejectDebouncer from "_util/debouncers/ProgressiveRejectDebouncer";

export const EmailLandingPage = () => {
	const initForm = {
		newPassword: "",
		confirmPassword: "",
	};
	const query = useQuery();
	const recaptchaRef = useRef(null);
	const resetPasswordRequesting = useSelector(({ auth }) => auth.resetPasswordRequesting);
	const resetPasswordSuccess = useSelector(({ auth }) => auth.resetPasswordSuccess);
	const resetPasswordError = useSelector(({ auth }) => auth.resetPasswordError);
	const [loading, setLoading] = useState(true);
	const [formData, setFormData] = useState(initForm);
	const [isChanged, setIsChanged] = useState(false);
	const [initialized, setInitialized] = useState(false);
	const [isSubmitting, setSubmitting] = useState(false);
	const dispatch = useDispatch();
	const [debouncer] = useState(() => new ProgressiveRejectDebouncer(1 * 1000));
	const mode = query.get("mode");
	const actionCode = query.get("oobCode");
	const reset = query.get("reset");
	const [popupDetail, setPopupDetail] = useState({
		title: "",
		message: "",
		code: "",
	});

	const enterPress = useKey("enter");

	useEffect(() => {
		if (enterPress) {
			handleSubmit();
		}
	}, [enterPress]);

	useEffect(() => {
		async function verifyCode(auth, code) {
			try {
				switch (mode) {
					case "resetPassword":
						await verifyPasswordResetCode(auth, code);
						break;
					case "verifyEmail":
						await applyActionCode(auth, code);
						toast.success("成功確認電郵地址");
						history.push("/");
						break;
					default:
						throw new Error();
				}
				setLoading(false);
			} catch (error) {
				const popupTitle = {
					resetPassword: reset ? "重設密碼不成功" : "設定密碼不成功",
					verifyEmail: "驗證電子郵件地址",
				};
				if (error && error.constructor && error.constructor.name === "FirebaseError") {
					if (error && error.code === "auth/expired-action-code") {
						setPopupDetail({
							title: popupTitle[mode],
							message: authConstants.errorCode.EXPIRED_OOB_CODE,
							code: error.code,
						});
					} else if (error && error.code === "auth/invalid-action-code") {
						toast.error("電子郵郵件鏈接無效或已被使用，如有疑問請聯繫管理員。");
						history.push("/");
					} else if (error && error.code === "auth/user-disabled") {
						toast.error("賬號已被暫停使用，如有疑問請聯繫管理員。");
						history.push("/");
					} else if (error && error.code === "auth/user-not-found") {
						toast.error("沒有找到該賬號，如有疑問請聯繫管理員。");
						history.push("/");
					} else {
						toast.error("系統暫時無法使用，請稍後再試或聯繫管理員。");
						history.push("/");
					}
				} else {
					toast.error("電子郵郵件鏈接無效或已被使用，如有疑問請聯繫管理員。");
					history.push("/");
				}
			}
		}
		const auth = getAuth();
		verifyCode(auth, actionCode);
		dispatch({ type: authConstants.type.AUTH_INIT });
		setInitialized(true);
	}, []);

	useEffect(() => {
		if (initialized) {
			if (!resetPasswordRequesting && isChanged) {
				setSubmitting(false);
				if (resetPasswordError === authConstants.errorCode.EXPIRED_OOB_CODE) {
					setPopupDetail({
						title: reset ? "重設密碼不成功" : "設定密碼不成功",
						message: authConstants.errorCode.EXPIRED_OOB_CODE,
						code: "auth/expired-action-code",
					});
				} else if (resetPasswordError) {
					toast.error(resetPasswordError);
				}
			}
			if (
				!resetPasswordRequesting &&
				(resetPasswordSuccess ||
					resetPasswordError === "現在無法登錄。" ||
					resetPasswordError === "電子郵郵件鏈接無效或已被使用，如有疑問請聯繫管理員。" ||
					resetPasswordError === "賬號已被暫停使用，如有疑問請聯繫管理員。")
			) {
				setSubmitting(false);
				if (isChanged) {
					// Prepare to leave
					setIsChanged(false);
				} else {
					history.push("/");
				}
			}
		}
	}, [initialized, resetPasswordRequesting, resetPasswordSuccess, isChanged, resetPasswordError]);

	const handleChange = ({ key, value }) => {
		setFormData({ ...formData, [key]: value });
		setIsChanged(true);
	};

	const onDialogClose = () => {
		setPopupDetail("");
		setIsChanged(false);
		history.push("/");
	};

	const handleBack = () => {
		history.push("/");
	};

	const handleResend = async (value) => {
		try {
			await authService.resendEmail({ actionCode, mode, recaptchaValue: value });
			toast.success("成功重發電子郵件");
			history.push("/");
		} catch (err) {
			toast.error(`請稍後再試`);
		}
	};

	const handleSubmit = async () => {
		if (!formData.newPassword) {
			toast.error("請填寫新密碼");
			return;
		}
		if (!formData.confirmPassword) {
			toast.error("請填寫再次輸入新密碼");
			return;
		}
		if (formData.newPassword !== formData.confirmPassword) {
			toast.error("確認密碼不匹配");
			return;
		}
		if (!debouncer.token()) {
			toast.error(`請稍後再試`);
			return;
		}
		const message = validatePassword(formData.newPassword);
		if (message) {
			toast.error(message);
			return;
		}
		if (mode === "resetPassword") {
			try {
				const reacptchaValue = await recaptchaRef.current.executeAsync();
				recaptchaRef.current.reset();
				dispatch(authAction.resetPassword(actionCode, reacptchaValue, formData.newPassword, reset));
			} catch {
				setSubmitting(false);
			}
		}
	};

	if (mode === "resetPassword" && _.isEmpty(popupDetail.code)) {
		return (
			<React.Fragment>
				{(!!resetPasswordRequesting || loading || !!isSubmitting) && <Loading />}
				<Prompt when={isChanged} message={reset ? "尚未重設密碼，確定離開？" : "尚未設定密碼，確定離開？"} />
				<PageContainer>
					<RightContainer>
						<AdminSectionbar label={reset ? generalConstants.EMAIL_LANDING_PAGE_TITLE_RESET : generalConstants.EMAIL_LANDING_PAGE_TITLE} />
						<ContentContainer>
							<RowInBlock>
								<ContentLeft>
									<TitleFieldLabel>- {reset ? "重設" : "設定"}密碼</TitleFieldLabel>
									<Block style={{ width: "90%" }} marginTop="19px">
										<TextFieldLabel>新密碼</TextFieldLabel>
										<TextField
											placeholder="新密碼"
											type="password"
											value={formData.newPassword}
											name="newPassword"
											handleChange={handleChange}
											disabled={isSubmitting}
										/>
									</Block>
									<Block style={{ width: "90%" }} marginTop="19px">
										<TextFieldLabel>請再次輸入新密碼</TextFieldLabel>
										<TextField
											placeholder="請再次輸入新密碼"
											type="password"
											value={formData.confirmPassword}
											name="confirmPassword"
											handleChange={handleChange}
											disabled={isSubmitting}
										/>
									</Block>
								</ContentLeft>

								<ContentRight></ContentRight>
							</RowInBlock>
							<ReCaptchaWithButton>
								<ReCaptchaContainer>
									<ReCAPTCHA
										badge={"inline"}
										ref={recaptchaRef}
										sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
										size={"invisible"}
										hl="zh-HK"
										onChange={() => {
											setSubmitting(true);
										}}
									/>
								</ReCaptchaContainer>

								<ButtonContainer>
									<CancelButton label={"取消"} style={{ marginRight: "15px", marginTop: "15px" }} handleOnClick={handleBack} />
									<SubmitButton
										label={"確認"}
										style={{ marginRight: "15px", marginTop: "15px" }}
										handleOnClick={handleSubmit}
										active={isChanged && !resetPasswordRequesting}
										disabled={resetPasswordRequesting}
									/>
								</ButtonContainer>
							</ReCaptchaWithButton>
						</ContentContainer>
						<AdminFooter></AdminFooter>
					</RightContainer>
				</PageContainer>
				<BlockMobileOverlay />
			</React.Fragment>
		);
	} else if (popupDetail.code === "auth/expired-action-code") {
		return (
			<>
				<EmailExpiredDialog popupDetail={popupDetail} onClose={onDialogClose} handleResend={handleResend} />
			</>
		);
	} else {
		return null;
	}
};

const ContentLeft = styled.div`
	flex: 0 0 400px;
	min-height: 250px;
	padding-left: 10px;
	max-width: 220px;
`;

const ContentRight = styled.div`
	flex: 1;
	padding-left: 20px;
`;

const Block = styled.div`
	margin-top: ${(props) => props.marginTop || "40px"};
	flex-direction: column;
`;

const RowInBlock = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.marginBottom || "10px"};
	flex-direction: row;
`;

const TextFieldLabel = styled.div`
	color: black;
	font-size: 13px;
	line-height: 1.5;
	font-weight: bold;
	margin-bottom: 10px;
`;
const TitleFieldLabel = styled.div`
	color: #333399;
	font-size: 14px;
	line-height: 1.5;
	font-weight: bold;
	margin-bottom: 10px;
`;

const ReCaptchaWithButton = styled.div`
	display: flex;
	flex-direction: column;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		flex-direction: column;
		justify-content: stretch;
	}
`;

const ReCaptchaContainer = styled.div`
	padding-left: 15px;
	padding-bottom: 15px;
`;

const ButtonContainer = styled.div`
	text-align: right;
	> button {
		width: 160px;
		font-weight: bold;
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			width: 100%;
		}
	}
`;
