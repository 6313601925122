import { combineReducers } from "redux";

import authReducer from "./auth.reducer";
import messageReducer from "./message.reducer";
import schoolReducer from "./school.reducer";
import templateReducer from "./template.reducer";
import { authConstants } from "_constants";

const appReducer = combineReducers({
	auth: authReducer,
	message: messageReducer,
	school: schoolReducer,
	template: templateReducer,
});

const rootReducer = (state, action) => {
	// clear all state from LOGOUT action.
	if (action.type === authConstants.type.LOGOUT) {
		state = {
			auth: state.auth,
		};
	}

	return appReducer(state, action);
};

export default rootReducer;
